type GET_TERRITORIES = {
	type: "GET_TERRITORIES",
	payload: Array<{
		id: string,
		name: string,
		zips: string,
	}>,
};

type REMOVE_TERRITORIES = {
	type: "REMOVE_TERRITORIES",
};

const territoryReducer = (state: Array<Object> = [], action: GET_TERRITORIES | REMOVE_TERRITORIES): Array<Object> => {
	switch (action.type) {
		case "GET_TERRITORIES":
			return action.payload;
		case "REMOVE_TERRITORIES":
			return [];
		default:
			return state;
	}
};

export default territoryReducer;
