import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import axios from "axios";
import { url } from "./config";
import moment from "moment";

const CheckLogsModal = (props: Object) => {
	// $FlowFixMe
	const [form] = Form.useForm();
	const [loadingState, setLoadingState] = useState(false);

	const onComponentMountAsync = () => {
		if (props.edit) {
			form.setFieldsValue({ ...props.edit, date: moment(props.edit.data) });
		} else {
			form.resetFields();
		}
	};

	useEffect(() => {
		onComponentMountAsync();
	});

	const onFinish = async values => {
		setLoadingState(true);
		if (props.edit) {
			// Update log
			await axios
				.put(`${url}/${props.edit.id}`, { ...values, payee_id: 0, date: values.date.format("YYYY-MM-DD HH:mm:ss"), user_id: 0 })
				.catch(e => notification.error({ title: "Whoops! Something went wrong", message: e.data.message }));
			form.resetFields();
			setLoadingState(false);
			return props.close();
		} else {
			// Create log
			await axios
				.post(url, { ...values, payee_id: 0, date: values.date.format("YYYY-MM-DD HH:mm:ss"), user_id: 0 })
				.catch(e => notification.error({ title: "Whoops! Something went wrong", message: e.data.message }));
			form.resetFields();
			setLoadingState(false);
			return props.close();
		}
	};

	return (
		<Modal
			forceRender
			title={"Create check log"}
			visible={props.visible}
			onCancel={() => {
				props.close();
			}}
			footer={null}>
			<Form form={form} onFinish={onFinish} initialValues={""} layout={"vertical"}>
				<Row gutter={10}>
					<Col span={24}>
						<Form.Item label={"Store"} name={"store_id"}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={"Check Number"}
							name={"check"}
							rules={[
								{
									required: true,
									message: "Please input check number.",
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={"Date"}
							name={"date"}
							rules={[
								{
									required: true,
									message: "Please input date.",
								},
							]}>
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					{/*<Col span={12}>*/}
					{/*	<Form.Item*/}
					{/*		label={"Contact"}*/}
					{/*		name={"payee_id"}*/}
					{/*		rules={[*/}
					{/*			{*/}
					{/*				required: true,*/}
					{/*				message: "Please input contact.",*/}
					{/*			},*/}
					{/*		]}>*/}
					{/*		<Input />*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					<Col span={12}>
						<Form.Item
							label={"Purpose"}
							name={"purpose_id"}
							rules={[
								{
									required: true,
									message: "Please input purpose.",
								},
							]}>
							<Select>
								<Select.Option value={1}>A/C Maintenance</Select.Option>
								<Select.Option value={2}>B2B Check</Select.Option>
								<Select.Option value={3}>C2B Check</Select.Option>
								<Select.Option value={4}>Car Wash</Select.Option>
								<Select.Option value={5}>Landscaping</Select.Option>
								<Select.Option value={6}>Other (see notes)</Select.Option>
								<Select.Option value={7}>Petty Cash Reimbursement</Select.Option>
								<Select.Option value={8}>Repo Company</Select.Option>
								<Select.Option value={9}>Signage</Select.Option>
								<Select.Option value={10}>Store Replacement/Maintenance</Select.Option>
								<Select.Option value={10}>VOIDED Check</Select.Option>
								<Select.Option value={12}>Window Cleaning</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={"Amount"}
							name={"amount"}
							rules={[
								{
									required: true,
									message: "Please input amount.",
								},
							]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label={"Notes"} name={"notes"}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item>
							<Button htmlType={"submit"} loading={loadingState} type={"primary"}>
								Save
							</Button>
						</Form.Item>
					</Col>
					<Col span={19}>
						<Form.Item>
							<Button onClick={props.close}>Cancel</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default CheckLogsModal;
