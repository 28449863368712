import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Card, Layout, notification } from "antd";
import { connect } from "react-redux";
import ReferralsPage from "../routes/referrals";
import AllStoresPage from "../routes/stores";
import StorePage from "../routes/store";
import DashboardPage from "../routes/dashboard";
import Weekly from "../routes/order";
import CalendarView from "../routes/calendar";
import WeekView from "../routes/weekly";
import Payees from "../routes/payees";
import { auth, storage } from "../firebase";
import Auth from "../routes/auth";
import AppHeader from "./header";
import MenuBar from "./menuBar";
import "./App.css";
import ProfilePage from "../routes/profile";
import Users from "../routes/users";
import Territories from "../routes/territories";
import type { Props, State } from "./types";
import PrivateRoute from "./privateRoute";
import Regions from "../routes/regions";
import CheckLogs from "../routes/check_logs";
import Notifications from "../routes/notifications";

// Reports
import Leads from "../routes/reports/leads";
import Loans from "../routes/reports/loans";
import Visits from "../routes/reports/visits";
import Downloads from "../routes/reports/downloads";
import { getTerritories } from "../redux/actions/getTerritories";

const { Content, Footer } = Layout;

class App extends React.Component<Props, State> {
	state = {
		isUser: false,
		url: "",
		progress: 0,
	};

	componentDidMount() {
		this.props.reloadTerritories();
	}

	uploadUserProfileImg = (image: Object) => {
		const user = auth.currentUser;
		const uploadTask = storage.ref(`profileImages/${image.name}`).put(image);
		uploadTask.on(
			"state_changed",
			snapshot => {
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ progress });
			},
			error => {
				notification.error({ message: "Unable to upload image", description: error.message });
			},
			async (): any => {
				const url = await storage
					.ref("profileImages")
					.child(image.name)
					.getDownloadURL();
				this.props.updateProfilePicture(url);
				if (user) {
					await user.updateProfile({
						photoURL: url,
					});
				}
			}
		);
	};

	render() {
		return (
			<Router>
				{this.props.user?.uid ? (
					<Layout className="layout">
						<AppHeader
							avatarUrl={this.props.user.photoURL}
							onChangeProfilePic={e => {
								if (e.target.files[0]) {
									const image = e.currentTarget.files[0];
									this.uploadUserProfileImg(image);
								}
							}}
							logOut={() => {
								auth.signOut()
									.then(() => {
										this.props.removeTerritories();
										this.props.removeB2Bs();
										this.props.removeRegions();
										this.props.logOut();
										return null;
									})
									.catch(err => notification.error({ message: "Whoops! Something went wrong.", description: err.message }));
							}}
						/>
						<MenuBar role={this.props.role} />
						<Content style={{ paddingTop: 160 }} className="remove-print-padding">
							<Card className="remove-print-padding" style={{ background: "#fff" }}>
								<Switch>
									<Route exact path={"/login"} component={() => <Auth />} />
									<PrivateRoute exact path={"/"} component={() => <DashboardPage />} auth={this.props.user} />
									<PrivateRoute exact path={"/referrals"} component={() => <ReferralsPage />} auth={this.props.user} />
									<PrivateRoute exact path={"/b2bs"} component={() => <AllStoresPage />} auth={this.props.user} />
									<PrivateRoute exact path={"/store/:id"} component={StorePage} auth={this.props.user} />
									<PrivateRoute exact path={"/order"} component={() => <Weekly />} auth={this.props.user} />
									<PrivateRoute exact path={"/calendar"} component={() => <CalendarView />} auth={this.props.user} />
									<PrivateRoute exact path={"/weekly"} component={() => <WeekView />} auth={this.props.user} />
									<PrivateRoute exact path={"/payees"} component={() => <Payees />} auth={this.props.user} />
									<PrivateRoute exact path={"/profile"} component={() => <ProfilePage />} auth={this.props.user} />
									{this.props.role === "Admin" || this.props.role === "Manager" ? (
										<>
											<PrivateRoute exact path={"/reports/leads"} component={Leads} auth={this.props.user} />
											<PrivateRoute exact path={"/reports/loans"} component={Loans} auth={this.props.user} />
											<PrivateRoute exact path={"/reports/visits"} component={Visits} auth={this.props.user} />
											<PrivateRoute exact path={"/reports/downloads"} component={Downloads} auth={this.props.user} />

											<PrivateRoute exact path={"/check-logs"} component={CheckLogs} auth={this.props.user} />
											<PrivateRoute exact path={"/notifications"} component={Notifications} auth={this.props.user} />
											<PrivateRoute exact path={"/regions"} component={() => <Regions />} auth={this.props.user} />
											<PrivateRoute exact path={"/territories"} component={() => <Territories />} auth={this.props.user} />
											<PrivateRoute exact path={"/users"} component={Users} auth={this.props.user} />
										</>
									) : null}
								</Switch>
							</Card>
						</Content>
						<Footer style={{ textAlign: "center" }}>
							CRM ©2020 Developed by{" "}
							<a href="https://www.studiomediaagency.com" target="_blank" rel="noopener noreferrer">
								Studio Media
							</a>
						</Footer>
					</Layout>
				) : (
					<Content>
						<Auth />
					</Content>
				)}
			</Router>
		);
	}
}

const mapStateToProps = state => {
	return {
		territory: state.user?.territory ?? null,
		user: state.user,
		role: state.user?.role ?? null,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		reloadTerritories: () => dispatch(getTerritories()),
		logOut: () => dispatch({ type: "LOGOUT" }),
		removeB2Bs: () => dispatch({ type: "REMOVE_B2BS" }),
		removeRegions: () => dispatch({ type: "REMOVE_REGIONS" }),
		removeTerritories: () => dispatch({ type: "REMOVE_TERRITORIES" }),
		updateProfilePicture: payload => dispatch({ type: "UPDATE_PROFILE_PICTURE", payload }),
	};
};

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(App);
