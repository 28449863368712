import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { firestore } from "../../firebase";

const NotificationsModal = (props: Object) => {
	// $FlowFixMe
	const [form] = Form.useForm();
	const [loadingState, setLoadingState] = useState(false);
	const territories = useSelector(state => state.territories);
	const user = useSelector(state => state.user);

	const onFinish = async values => {
		setLoadingState(true);
		// Create notification
		await firestore.collection("notifications").add({ ...values, user: user.name, timestamp: new Date() });
		form.resetFields();
		setLoadingState(false);
		return props.close();
	};

	return (
		<Modal
			forceRender
			title={"Create notification"}
			visible={props.visible}
			onCancel={() => {
				props.close();
			}}
			footer={null}>
			<Form form={form} onFinish={onFinish} initialValues={""} layout={"vertical"}>
				<Row gutter={10}>
					<Col span={24}>
						<Form.Item
							label={"Notification Options"}
							name={"options"}
							rules={[
								{
									required: true,
									message: "Please add territories.",
								},
							]}>
							<Select mode={"tags"}>
								<Select.Option value={"push notification"}>Push Notification</Select.Option>
								<Select.Option value={"email"}>Email</Select.Option>
								<Select.Option value={"sms"}>SMS</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label={"Territories"}
							name={"territories"}
							rules={[
								{
									required: true,
									message: "Please add territories.",
								},
							]}>
							<Select mode={"tags"}>
								{territories.map(item => (
									<Select.Option value={item.id} key={item.id}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label={"Title"}
							name={"title"}
							rules={[
								{
									required: true,
									message: "Please add territories.",
								},
							]}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label={"Body"}
							name={"body"}
							rules={[
								{
									required: true,
									message: "Please add territories.",
								},
							]}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item>
							<Button htmlType={"submit"} loading={loadingState} type={"primary"}>
								Save
							</Button>
						</Form.Item>
					</Col>
					<Col span={19}>
						<Form.Item>
							<Button onClick={props.close}>Cancel</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default NotificationsModal;
