import React from "react";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select } from "antd";

type Props = {
	visible: boolean,
	onOk: Function,
	onCancel: Function,
	onDelete: Function,
	handleFile: Object,
	state: Object,
	onZipsChange: Object,
	onTerritoryChange: Object,
	onStoreChange: Object,
};

const AddTerritoryModal = (props: Props) => {
	//$FlowFixMe
	let fileRef = React.createRef();

	const handleOpenFile = () => {
		//$FlowFixMe
		fileRef.current.click();
	};

	const { onOk, state, onCancel, onDelete, handleFile, onZipsChange, onTerritoryChange } = props;
	return (
		<Modal
			title={state.editing ? "Edit Territory" : "Add Territory"}
			confirmLoading={state.loading}
			visible={state.modalOpen}
			onOk={() => onOk()}
			onCancel={onCancel}
			okText={"Save"}
			cancelText={"Cancel"}
			footer={
				<Row gutter={10}>
					<Col style={{ textAlign: "left" }} span={12}>
						{state.editing ? (
							<Popconfirm title={"Are you sure you want to delete this territory?"} onConfirm={onDelete}>
								<Button type={"danger"}>Delete</Button>
							</Popconfirm>
						) : null}
					</Col>
					<Col style={{ textAlign: "right", display: "flex", alignItems: "center", justifyContent: "flex-end" }} span={12}>
						<input type="file" value={""} ref={fileRef} onChange={handleFile} style={{ display: "none" }} />
						<Button onClick={handleOpenFile}>Upload CSV</Button>
						<Button onClick={onCancel}>Cancel</Button>
						<Button onClick={() => onOk()} type={"primary"}>
							Save
						</Button>
					</Col>
				</Row>
			}>
			<Form>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item>
							Store
							<Input value={state.territory} disabled={state.editing} onChange={onTerritoryChange} />
						</Form.Item>
					</Col>
					{/*<Col span={12}>*/}
					{/*	<Form.Item>*/}
					{/*		Prism ID*/}
					{/*		<Input value={state.store} type={"number"} onChange={props.onStoreChange} />*/}
					{/*	</Form.Item>*/}
					{/*</Col>*/}
					<Col span={24}>
						<Form.Item>
							Zip Codes
							<Select
								mode="tags"
								defaultValue={state.zips}
								value={state.zips}
								onChange={onZipsChange}
								dropdownStyle={{
									display: "none",
								}}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default AddTerritoryModal;
