import React, { useState } from "react";
import { Modal, Select } from "antd";
import { useSelector } from "react-redux";
import { firestore } from "../../firebase";

export default function(props) {
	const [loading, setLoading] = useState(false);
	const [store, setStore] = useState(null);
	const stores = useSelector(state => state.b2bs);

	const ok = async () => {
		setLoading(true);

		const storeData = stores.find(x => x.id === store);
		await firestore.doc("payees/" + props.payeeID).update({
			b2b: firestore.doc("B2Bs/" + store),
			b2bName: storeData?.company,
			b2bZip: storeData?.zip,
			territory: storeData?.territory?.id ? firestore.doc("territories/" + storeData.territory.id) : null,
			territoryName: storeData?.territoryName,
		});

		props.ok();
		props.close();
		setLoading(false);
	};

	return (
		<Modal title="Link B2B Contact to B2B Store" visible={props.visible} onOk={ok} onCancel={props.close} okButtonProps={{ loading }}>
			<b>Select a Store Front</b>
			<br />
			<Select style={{ width: "100%" }} onChange={setStore}>
				{stores.map(store => {
					return (
						<Select.Option key={store.id} value={store.id}>
							{store.company} ({store.address})
						</Select.Option>
					);
				})}
			</Select>
		</Modal>
	);
}
