import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyA6X32gpZOxr6J00_Cau88pMepLPO_GAiI",
	authDomain: "discount-loans-crm.firebaseapp.com",
	databaseURL: "https://discount-loans-crm.firebaseio.com",
	projectId: "discount-loans-crm",
	storageBucket: "discount-loans-crm.appspot.com",
	messagingSenderId: "346887831733",
	appId: "1:346887831733:web:530b115dcbebe12c4c3efc",
	measurementId: "G-DT22MX012M",
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const auth = firebase.auth();
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const storage = firebase.storage();
const geoPoint = (lat: number, lng: number) => new firebase.firestore.GeoPoint(lat, lng);

const secondary = firebase.initializeApp(firebaseConfig, "Secondary");
const firebaseAdmin = secondary.auth();

// if (window.location.hostname === "localhost") {
// 	firestore.settings({
// 		host: "localhost:5002",
// 		ssl: false,
// 	});
// }

export { firestore, auth, EmailAuthProvider, firebaseAdmin, geoPoint, storage };
