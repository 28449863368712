type LOGIN = {
	type: "LOGIN",
	payload: {
		uid: string,
		prismID: number,
		photoURL?: string,
		email: string,
		name: string,
		role: string,
		store: number,
		territory: string,
		region: string | null,
		regionTerritories: Array<>,
		zips: Array<number>,
	},
};
type LOGOUT = { type: "LOGOUT" };

type UPDATE_PROFILE_PICTURE = {
	type: "UPDATE_PROFILE_PICTURE",
	payload: string,
};

type State = {
	+uid: string | null,
	+prismID: number,
	+photoURL?: string | null,
	+email: string | null,
	role: string | null,
	store: number | null,
	territory: string | null,
	region: string | null,
	regionTerritories: Array<>,
	zips: Array<number>,
};

const initialState = {
	uid: null,
	prismID: 0,
	photoURL: null,
	email: null,
	name: null,
	role: null,
	store: null,
	territory: null,
	territoryName: null,
	region: null,
	regionTerritories: [],
	zips: [],
};

const userReducer = (state: State = initialState, action: LOGIN | LOGOUT | UPDATE_PROFILE_PICTURE): State => {
	switch (action.type) {
		case "LOGIN":
			return {
				...state,
				uid: action.payload.uid,
				prismID: action.payload.prismID ?? 0,
				photoURL: action.payload.photoURL,
				email: action.payload.email,
				name: action.payload.name,
				role: action.payload.role,
				store: action.payload.store,
				territory: action.payload.territory,
				territoryName: action.payload.territoryName,
				region: action.payload.region,
				regionTerritories: action.payload.regionTerritories,
				zips: action.payload.zips,
			};
		case "LOGOUT":
			return initialState;
		case "UPDATE_PROFILE_PICTURE":
			return {
				...state,
				photoURL: action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
