import { firestore } from "../../firebase";
import async from "async";

export const getTerritories = () => {
	return async (dispatch: Function) => {
		return firestore
			.collection("territories")
			.orderBy("name", "asc")
			.onSnapshot(snapshot => {
				const payload = [];
				async.eachOf(
					snapshot.docs,
					(territory, key, callback) => {
						payload.push({
							id: territory.id,
							...territory.data(),
						});
						callback();
					},
					() =>
						dispatch({
							type: "GET_TERRITORIES",
							payload,
						})
				);
			});
	};
};
