import React from "react";
import async from "async";
import { Select, Row, Col } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase";

import editableRow from "./editableRow";
import editableCell from "./editableCell";
import Cardbox from "../../components/cardbox";
import Table from "../../components/smartTable";
import data from "./data";
import type { Props, State } from "./types";

class Order extends React.Component<Props, State> {
	constructor() {
		super();

		//$FlowFixMe
		this.subscribed = false;
	}
	state = {
		data: [],
		loading: true,
		totalStores: 0,
		searchedText: "",
		searchedColumn: "",
		territory: this.props?.territory,
	};

	async componentDidMount(): any {
		//$FlowFixMe
		this.subscribed = true;
		if (this.props.territory) {
			this.getTerritoryOrder(this.props.territory);
		} else {
			this.setState({ loading: false });
		}
	}

	componentWillUnmount(): any {
		//$FlowFixMe
		this.subscribed = false;
	}

	getTerritoryOrder = async (territory: string): any => {
		const useTerritory = territory ?? this.state.territory;
		const territoryRef = await firestore.doc("territories/" + useTerritory).get();
		const territoryZips = territoryRef.data().zips;

		firestore
			.collection("B2Bs")
			.orderBy("company", "asc")
			.onSnapshot(x => {
				const useTerritory = territory ?? this.state.territory;
				const territoriesStores = x.docs.filter(x => x.data().active !== false && territoryZips.includes(x.data().zip));
				this.setState({
					data: territoriesStores.map(x => ({
						key: x.id,
						defaultDay: x.data()[useTerritory]?.defaultDay ?? 0,
						defaultWeek: x.data()[useTerritory]?.defaultWeek ?? 0,
						defaultStop: x.data()[useTerritory]?.defaultStop ?? 0,
						company: x.data().company ? x.data().company : "-",
						address: x.data().address,
						city: x.data().city,
						zip: x.data().zip,
						type: x.data().type,
						seen: x.data()[useTerritory]?.seen === undefined ? null : x.data()[useTerritory]?.seen,
					})),
					loading: false,
					territory: useTerritory ?? this.state.territory,
					totalStores: territoriesStores.length,
				});
			});
	};

	render() {
		const cityFilter = [];
		const zipFilter = [];
		async.eachOfSeries(this.state.data, (x, index, callback) => {
			if (!cityFilter.includes(x.city)) {
				cityFilter.push(x.city);
			}
			callback();
		});
		async.eachOfSeries(this.state.data, (x, index, callback) => {
			if (!zipFilter.includes(x.zip)) {
				zipFilter.push(x.zip);
			}
			callback();
		});
		const components = {
			body: {
				row: editableRow,
				cell: editableCell,
			},
		};
		let weekColumns = [
			{
				title: "Week",
				dataIndex: "defaultWeek",
				editable: true,
				filters: data.defaultWeek,
				onFilter: (value, record) => record.defaultWeek === value,
				sorter: (a, b) => a.defaultWeek - b.defaultWeek,
				render: number => {
					if (!number) {
						return "-";
					}
					const week: Object = data.defaultWeek.find(x => x.value === number);
					return week.text;
				},
			},
			{
				title: "Day",
				dataIndex: "defaultDay",
				editable: true,
				filters: data.defaultDay,
				onFilter: (value, record) => record.defaultDay === value,
				sorter: (a, b) => a.defaultDay - b.defaultDay,
				render: number => {
					if (!number) {
						return "-";
					}
					const day: Object = data.defaultDay.find(x => x.value === number);
					return day.text;
				},
			},
			{
				title: "Order",
				dataIndex: "defaultStop",
				editable: true,
				filters: data.defaultStop,
				onFilter: (value, record) => record.defaultStop === value,
				sorter: (a, b) => a.defaultStop - b.defaultStop,
				render: number => {
					if (!number) {
						return "-";
					}
					const stop: Object = data.defaultStop.find(x => x.value === number);
					return stop.text;
				},
			},
			{
				title: "B2B Store",
				dataIndex: "company",
				sorter: (a, b) => a.company.length - b.company.length,
			},
			{
				title: "Address",
				dataIndex: "address",
				search: "address",
			},
			{
				title: "City",
				dataIndex: "city",
				filters: cityFilter.map(x => {
					return { text: x, value: x };
				}),
				onFilter: (value, record) => record.city.indexOf(value) === 0,
				sorter: (a, b) => a.city.length - b.city.length,
			},
			{
				title: "Zip",
				dataIndex: "zip",
				filters: zipFilter.map(x => {
					return { text: x, value: x };
				}),
				onFilter: (value, record) => record.zip.toString().indexOf(value) === 0,
				sorter: (a, b) => a.zip.length - b.zip.length,
			},
			{
				title: "Type",
				dataIndex: "type",
				width: 200,
				filters: [
					{
						text: "Finance",
						value: "Finance",
					},
					{
						text: "Non Finance",
						value: "Non Finance",
					},
				],
				onFilter: (value, record) => record.type === value,
			},
			{
				title: "Detail",
				key: "details",
				render: (item, index) => (
					<Link key={index} to={`/store/${index.key}`}>
						View Detail
					</Link>
				),
			},
		];
		const columns = weekColumns.map(col => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: record => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					handleSave: this.props.handleSave,
					state: this.state.data,
					uid: this.props.user.uid,
					territory: this.state.territory,
				}),
			};
		});

		return (
			<Cardbox>
				<h1>Routing Schedule</h1>
				<Row>
					<Col span={12}>
						<h3>({this.state.totalStores}) Stores</h3>
					</Col>
					<Col span={12} style={{ textAlign: "right" }}>
						{this.props.user.role === "Admin" || this.props.user.role === "Manager" ? (
							<Select
								placeholder={"Select Territory"}
								size={"large"}
								style={{ width: 200, textAlign: "left" }}
								onChange={e => this.getTerritoryOrder(e)}>
								{this.props.territories.map(territory => (
									<Select.Option key={territory.id} value={territory.id} className="year-item">
										{territory.name}
									</Select.Option>
								))}
							</Select>
						) : null}
					</Col>
				</Row>
				<br />
				<Table
					components={components}
					rowClassName={() => "editable-row"}
					bordered
					loading={this.state.loading}
					dataSource={this.state.data}
					columns={columns}
					onRowsChange={totalStores => this.setState({ totalStores })}
				/>
			</Cardbox>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		territory: state.user.territory,
		territories: state.territories,
	};
};

//$FlowFixMe
export default connect(mapStateToProps)(Order);
