import React from "react";
import { Select } from "antd";
import { firestore } from "../../firebase";
import { connect } from "react-redux";
import getStores from "../../redux/actions/getStores";

type Props = {
	store: {
		id: string,
		active: boolean,
	},
	getStores: Function,
};
type State = {
	active: boolean,
};

class Status extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			active: props.store.active || props.store.active === undefined,
		};
	}

	saveStatus = async () => {
		await firestore.doc("B2Bs/" + this.props.store.id).update({ active: this.state.active });
		this.props.getStores();
	};

	render() {
		return (
			<Select
				style={{ width: 100 }}
				defaultValue={this.state.active.toString()}
				onChange={e => this.setState({ active: JSON.parse(e) }, this.saveStatus)}>
				<Select.Option key={0} value={"true"}>
					Active
				</Select.Option>
				<Select.Option key={1} value={"false"}>
					Disabled
				</Select.Option>
			</Select>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getStores: () => dispatch(getStores()),
	};
};

// $FlowFixMe
export default connect(null, mapDispatchToProps)(Status);
