import React from "react";
import async from "async";
import moment from "moment";
import { Table, Typography } from "antd";

type Props = {
	data: Array<Object>,
	limit: number,
	show: string,
};

type State = {
	months: Array<Object>,
	columns: Array<Object>,
	data: Array<Object>,
	loading: boolean,
};

const { Title } = Typography;

class MonthsTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			months: [],
			columns: [],
			data: [],
			loading: true,
		};
	}

	componentDidMount(): void {
		this.getMonths();
	}

	UNSAFE_componentWillReceiveProps(nextProps: any, nextContext: any): void {
		this.formatData().catch();
	}

	getMonths = () => {
		let months = [];
		for (let i = 0; i < this.props.limit; i++) {
			months.unshift(
				moment()
					.subtract(i, "months")
					.startOf("month")
			);
		}
		this.setState({ months });
	};

	formatData = async () => {
		await this.setState({ loading: true });
		const months = this.state.months;
		const data = [];

		await async.eachOf(
			this.props.data,
			async.asyncify(payee => {
				const payeeData = {
					name: payee.name,
					interactions: [],
					leads: [],
					loans: [],
				};

				async.eachOfSeries(
					months,
					async.asyncify(month => {
						payeeData.interactions.push(payee.interactions.filter(e => e.startOf("month").isSame(month)).length);
						payeeData.leads.push(
							payee.leads.filter(e =>
								moment(e.created)
									.startOf("month")
									.isSame(month)
							).length
						);
						payeeData.loans.push(
							payee.leads.filter(
								e =>
									moment(e.created)
										.startOf("month")
										.isSame(month) && e.status === 3
							).length
						);
					})
				);

				data.push(payeeData);

				const columns = [
					{
						title: "Contacts",
						dataIndex: "name",
					},
				];

				months.forEach((month, index) => {
					let total = 0;
					data.forEach(payee => {
						total += payee[this.props.show.toLowerCase()][index];
					});
					columns.push({
						title: `(${total}) ` + month.format("MMM"),
						dataIndex: this.props.show.charAt(0).toLowerCase() + this.props.show.slice(1),
						key: index,
						render: e => <span>{e[index]}</span>,
					});
				});

				this.setState({
					columns,
				});
			})
		);

		this.setState({ data, loading: false });
	};

	render() {
		return (
			<>
				<Title level={3}>{this.props.show}</Title>
				<Table rowKey={"name"} loading={this.state.loading} bordered pagination={false} columns={this.state.columns} dataSource={this.state.data} />
			</>
		);
	}
}

export default MonthsTable;
