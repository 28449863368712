import React from "react";
import { Row, Col, Modal, Button, Spin } from "antd";
import { connect } from "react-redux";
import axios from "axios";

type Props = {
	visible: boolean,
	referral: {
		id: string,
		name: string,
		phone: string,
		matches: Array<Object>,
	},
	ok: Function,
	close: Function,
};

type State = {
	loading: boolean,
};

class Match extends React.Component<Props, State> {
	state = {
		loading: false,
	};

	link = async (application: string) => {
		await this.setState({ loading: true });
		await axios({
			url: "https://b2b.discount-loans.com/assignReferral",
			params: {
				crm: true,
				referral: this.props.referral.id,
				application,
			},
		});
		this.props.ok();
		await this.setState({ loading: false });
		return this.props.close();
	};

	ignore = async (application: string) => {
		await this.setState({ loading: true });
		await axios({
			url: "https://b2b.discount-loans.com/ignorePossibleMatch",
			params: {
				referral: this.props.referral.id,
				application,
			},
		});
		this.props.ok();
		await this.setState({ loading: false });
		return this.props.close();
	};

	render() {
		return (
			<Modal
				width={800}
				visible={this.props.visible}
				title={"Match to Application"}
				footer={[
					<Button type={"danger"} onClick={this.props.close}>
						Close
					</Button>,
				]}
				onCancel={this.props.close}>
				{this.state.loading ? (
					<div
						style={{
							top: 55,
							display: "flex",
							width: "92%",
							height: "calc(100% - 110px)",
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: "#FFF9",
							position: "absolute",
							zIndex: 9999,
						}}>
						<Spin />
					</div>
				) : null}
				<Row gutter={[10, 10]}>
					<Col span={6}>
						<b>Referrals's Name</b> <br />
						{this.props.referral.name}
					</Col>
					<Col span={6}>
						<b>Referrals's Phone</b> <br />
						{this.props.referral.phone}
					</Col>
				</Row>
				<br />
				<br />
				<Row gutter={[10, 10]}>
					<Col span={6}>
						<b>Customer's Name</b>
					</Col>
					<Col span={6}>
						<b>Customer's Phone</b>
					</Col>
					<Col span={6}>
						<b>Customer's City</b>
					</Col>
					<Col span={6}>
						<b>Actions</b>
					</Col>
				</Row>
				{this.props.referral.matches.map(match => (
					<Row gutter={[10, 10]} key={match.id}>
						<Col span={6}>{match.name}</Col>
						<Col span={6}>{match.phone}</Col>
						<Col span={6}>{match.city}</Col>
						<Col span={6}>
							<Button size={"small"} onClick={() => this.link(match.id)}>
								Match
							</Button>
							<Button size={"small"} onClick={() => this.ignore(match.id)} style={{ marginLeft: 3 }}>
								Ignore
							</Button>
						</Col>
					</Row>
				))}
			</Modal>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

//$FlowFixMe
export default connect(mapStateToProps)(Match);
