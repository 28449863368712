import { firestore } from "../../firebase";
export default () => {
	return async (dispatch: Function) => {
		const regionsCollection = await firestore.collection("regions").get();
		const regions = regionsCollection.docs.map(x => {
			const territories = x.data().territories;

			return {
				id: x.id,
				name: x.data().name,
				territories: territories.map(x => x.id),
			};
		});

		dispatch({
			type: "GET_REGIONS",
			payload: regions,
		});
	};
};
