import React from "react";
import axios from "axios";
import moment from "moment";
import { Row, Col, Button, Typography } from "antd";
import { CSVLink } from "react-csv";

import SmartTable from "../../components/smartTable";

export default () => {
	const [loading, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		axios
			.post("https://us-central1-digital-loans.cloudfunctions.net/referralsReports-B2BVisits", {
				data: {},
			})
			.then(res => {
				setData(res.data.result);
				return setLoading(false);
			})
			.catch();
	}, []);

	return (
		<>
			<Row justify={"space-between"}>
				<Col>
					<Typography.Title style={{ marginBottom: 0 }}>Visits</Typography.Title>
				</Col>
				<Col style={{ paddingTop: 8 }}>
					<CSVLink
						data={data.map(x => ({
							...x,
							time: moment(x.date).format("HH:mm"),
							date: moment(x.date).format("MM/DD/YY"),
							distance: x.distance ? x.distance.toFixed(2) : "",
							distanceMts: x.distance ? (x.distance / 100).toFixed(2) : "",
						}))}
						filename={"visits.csv"}>
						<Button>Download CSV</Button>
					</CSVLink>
				</Col>
			</Row>
			<br />
			<SmartTable
				rowKey={e => e.id}
				loading={loading}
				pagination={{ defaultPageSize: 100 }}
				dataSource={data}
				style={{ minHeight: "72vh" }}
				columns={[
					{
						title: "Date",
						dataIndex: "date",
						render: d => moment(d).format("ll"),
					},
					{
						title: "Hora",
						dataIndex: "date",
						render: d => moment(d).format("HH:MM"),
					},
					{
						title: "Store",
						dataIndex: "store",
					},
					{
						title: "Address",
						dataIndex: "address",
					},
					{
						title: "Zip",
						dataIndex: "zip",
					},
					{
						title: "Territory",
						dataIndex: "territory",
					},
					{
						title: "Representative",
						dataIndex: "rep",
					},
					{
						title: "Manager",
						dataIndex: "manager",
					},
				]}
			/>
		</>
	);
};
