import React from "react";
import { connect } from "react-redux";
import { Col, Form, Input, Modal, Row, Select } from "antd";

type Props = {
	isVisible: boolean,
	title: string,
	onCancel: Function,
	zips: Array<number>,
	onInputChange: Object,
	onRegionChange: Object,
	onTerritoryChange: Object,
	onRoleChange: Object,
	value: Object,
	saveUser: Function,
	regions: Array<Object>,
	territories: Array<Object>,
};

type State = {};

class UserModal extends React.Component<Props, State> {
	render() {
		const { isVisible, title, onRoleChange, onCancel, saveUser, onInputChange, onRegionChange, onTerritoryChange, value } = this.props;
		return (
			<Modal title={title} visible={isVisible} onCancel={onCancel} onOk={saveUser}>
				<Form>
					<Row gutter={5}>
						<Col span={8}>
							<Form.Item>
								Name
								<Input name={"name"} value={value.name} onChange={onInputChange} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item>
								Email
								<Input name={"email"} value={value.email} onChange={onInputChange} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item>
								Choose Role for User
								<Select value={value.role} onChange={onRoleChange}>
									<Select.Option key={0} value={"User"}>
										User
									</Select.Option>
									<Select.Option key={1} value={"Manager"}>
										Manager
									</Select.Option>
									<Select.Option key={2} value={"Admin"}>
										Admin
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={5}>
						<Col span={12}>
							<Form.Item>
								Password
								<Input name={"password"} value={value.password} onChange={onInputChange} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item>
								Confirm Password
								<Input name={"confirmPassword"} value={value.confirmPassword} onChange={onInputChange} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={5}>
						<Col span={24}>
							{value.role === "Manager" ? (
								<Form.Item>
									Assign Region
									<Select name={"region"} value={value.region} onChange={onRegionChange}>
										{this.props.regions.map(x => (
											<Select.Option key={x.id} value={x.id}>
												{x.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							) : null}

							{value.role === "User" ? (
								<Form.Item>
									Assign Territory
									<Select name={"territory"} value={value.territory} onChange={onTerritoryChange}>
										{this.props.territories.map(x => (
											<Select.Option key={x.id} value={x.id}>
												{x.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							) : null}
						</Col>
					</Row>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = state => ({
	regions: state.regions,
	territories: state.territories,
});

//$FlowFixMe
export default connect(mapStateToProps)(UserModal);
