import React from "react";
import axios from "axios";
import { Typography, Button, Modal, Select, InputNumber, Input } from "antd";
import { useSelector } from "react-redux";

type Props = {
	id: null | string,
	status: null | string,
	origin: null | string,
	payee: null | string,
	store: null | string,
	commission: null | number,
	close: Function,
};

function Status(props: Props) {
	const user = useSelector(state => state.user);
	const [status, setStatus] = React.useState(props.status);
	const [loading, setLoading] = React.useState(false);
	const [commission, setCommission] = React.useState(props.commission);
	const [notes, setNotes] = React.useState(null);

	React.useEffect(() => {
		setStatus(props.status);
		setCommission(props.commission);
	}, [props]);

	const save = async () => {
		if (status === "APPROVED") {
			if (!props.payee || !props.store) {
				return Modal.error({
					title: "Something went wrong",
					content: "This referral does not have a B2B Contact or Store linked. Please make sure they are properly linked before funding.",
				});
			} else if (!commission) {
				return Modal.error({
					title: "Something went wrong",
					content: "The referral fee is a required field",
				});
			} else {
				setLoading(true);
				await axios({
					url: "https://b2b.discount-loans.com/fundReferral",
					params: {
						id: props.id,
						store: user.store,
						notes: notes,
						commission: commission,
						prismID: user.prismID,
					},
				});
				await props.ok();
				await props.close();
				return setLoading(false);
			}
		} else if (status === "NO LOAN") {
			return Modal.confirm({
				title: "Please Confirm",
				content: "This will change the status to NO LOAN",
				okText: "Continue",
				okType: "danger",
				onOk: async () => {
					await axios({
						url: "https://b2b.discount-loans.com/deleteReferral",
						params: {
							id: props.id,
							close: true,
						},
					});
					await props.ok();
					await props.close();
				},
			});
		} else if (status === "PENDING") {
			setLoading(true);
			await axios({
				url: "https://b2b.discount-loans.com/updateReferral",
				params: {
					id: props.id,
					status_id: 1,
					status: "PENDING",
				},
			});
			await props.ok();
			await props.close();
			return setLoading(false);
		} else if (status === "CONTACTED" || status === "NO ANSWER" || status === "LOAN: NO REFERRAL FEE" || status === "PAID") {
			setLoading(true);
			await axios({
				url: "https://b2b.discount-loans.com/updateReferral",
				params: {
					id: props.id,
					status_id: 1,
					status,
				},
			});
			await props.ok();
			await props.close();
			return setLoading(false);
		} else {
			return props.close();
		}
	};

	return (
		<Modal
			visible={Boolean(props.id)}
			title={"Change Status"}
			footer={[
				<Button key={"close"} type={"danger"} onClick={props.close}>
					Close
				</Button>,
				<Button key={"save"} type={"primary"} loading={loading} onClick={save}>
					Save
				</Button>,
			]}
			onCancel={props.close}>
			<Typography.Text strong>New Status</Typography.Text>
			<Select value={status} onChange={setStatus} style={{ width: "100%", marginBottom: 12 }}>
				{user.role !== "User" && props.origin !== "C2B" && props.status === "APPROVED" ? (
					<Select.Option value={"APPROVED"}>APPROVE FOR PAYMENT</Select.Option>
				) : null}
				<Select.Option value={"PENDING"}>PENDING</Select.Option>
				<Select.Option value={"CONTACTED"}>CONTACTED</Select.Option>
				<Select.Option value={"LOAN: NO REFERRAL FEE"}>LOAN: NO REFERRAL FEE</Select.Option>
				<Select.Option value={"NO ANSWER"}>NO ANSWER</Select.Option>
				<Select.Option value={"NO LOAN"}>NO LOAN</Select.Option>
				<Select.Option value={"PAID"}>PAID</Select.Option>
			</Select>
			{status === "APPROVED" ? (
				<>
					<Typography.Text strong>Referral Fee</Typography.Text>
					<InputNumber value={commission} onChange={setCommission} placeholder={"Select Amount"} style={{ width: "100%", marginBottom: 12 }} />
					<Typography.Text strong>Notes</Typography.Text>
					<Input.TextArea rows={5} onChange={e => setNotes(e)} />
				</>
			) : null}
		</Modal>
	);
}

export default Status;
