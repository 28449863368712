import React from "react";
import { Avatar, Col, Dropdown, Layout, Menu, Row, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../assets/logoTitle@2x.png";
import { Link } from "react-router-dom";

type Props = {
	avatarUrl: any,
	onChangeProfilePic: any,
	logOut: any,
};

const { Header } = Layout;

const AppHeader = (props: Props) => {
	const { avatarUrl, onChangeProfilePic, logOut } = props;
	return (
		<Header
			className="remove-print"
			style={{
				lineHeight: "100px",
				height: 100,
				position: "fixed",
				zIndex: 5,
				width: "100%",
				background: "#fff",
				paddingLeft: 30,
				paddingRight: 30,
			}}>
			<Row>
				<Col span={12}>
					<img alt="logo" style={{ height: 80 }} src={Logo} />
				</Col>
				<Col span={12} style={{ textAlign: "right" }}>
					<div
						style={{
							fontSize: 15,
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
						}}>
						<span style={{ paddingRight: 10 }}>
							<Link to={"/profile"}>MY PROFILE</Link>
						</span>
						<span style={{ fontSize: 30, fontWeight: "lighter", color: "000" }}>|</span>
						<Button type={"link"} onClick={logOut} style={{ paddingLeft: 10, paddingRight: 20 }}>
							LOG OUT
						</Button>
						<span>
							<Dropdown
								overlay={() => (
									<Menu>
										<Menu.Item>
											<span>
												<label htmlFor="file">Change Picture</label>
												<input
													type="file"
													name="file"
													id="file"
													style={{
														width: "0.1px",
														height: "0.1px",
														opacity: 0,
														overflow: "hidden",
														position: "absolute",
														zIndex: -1,
													}}
													onChange={onChangeProfilePic}
												/>
											</span>
										</Menu.Item>
									</Menu>
								)}>
								<Avatar size={50} src={avatarUrl} icon={<UserOutlined />} />
							</Dropdown>
						</span>
					</div>
				</Col>
			</Row>
		</Header>
	);
};

export default AppHeader;
