import React, { PureComponent } from "react";
import { Button, Col, notification, Row, Table, Tag, Typography } from "antd";
import getDistanceFromLatLonInKm from "../../utils/getDistance";
import InteractionsModal from "./interactions-modal";
import { connect } from "react-redux";
import { firestore, geoPoint } from "../../firebase";
import moment from "moment";
import async from "async";

type Props = {
	id: string,
	payees: Array<Object>,
	onChange: Function,
};

type State = {
	data: Array<Object>,
	modal: boolean,
	loading: boolean,

	interaction: string,
	payees: Array<Object>,
	notes: string,
};

class Visits extends PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			data: [],
			modal: false,
			loading: true,

			interaction: "",
			payees: [],
			notes: "",
		};
	}

	componentDidMount(): void {
		this.getData();
	}

	getData = () => {
		firestore
			.collection("B2Bs/" + this.props.id + "/visits")
			.orderBy("timestamp", "desc")
			.onSnapshot(snapshot => {
				const allVisits = [];
				async.eachOf(
					snapshot.docs,
					(interaction, key, callback) => {
						allVisits.push({
							id: interaction.id,
							date: moment(interaction.data().timestamp.toDate()),
							notes: interaction.data().notes,
							payees: interaction.data().payees,
							territoryName: interaction.data().territoryName,
							userName: interaction.data().userName,
							location: interaction.data().location,
							distance: interaction.data().distance,
							verified: interaction.data().verified,
						});
						callback();
					},
					() => {
						this.setState({ data: allVisits, loading: false });
					}
				);
			});
	};

	createNewInteraction = (notes: string) => {
		const payeesReferences = [];

		if (!notes) {
			return notification.error({
				message: "Whoops!",
				description: "You need to fill out the form before submitting.",
			});
		}
		if (this.state.payees) {
			this.state.payees.forEach(payee => {
				payeesReferences.push(firestore.collection("payees/").doc(payee));
			});
		}
		navigator.geolocation.getCurrentPosition(
			coordinates => {
				const latitude = coordinates.coords.latitude;
				const longitude = coordinates.coords.longitude;
				const geo = geoPoint(latitude, longitude);
				if (this.state.interaction) {
					this.updateToFirestore(geo, payeesReferences, notes);
				} else {
					this.saveToFirestore(geo, payeesReferences, notes);
				}
			},
			err => {
				notification.error({ message: "Could not get user location", description: err.message });
			}
		);
		return null;
	};

	updateToFirestore = (geo: Object, payeesReferences: Array<Object>, notes: string) => {
		return firestore
			.doc("B2Bs/" + this.props.id + "/visits/" + this.state.interaction)
			.update({
				notes,
				payees: payeesReferences.length ? payeesReferences : null,
			})
			.then(() => {
				notification.success({ message: "Success!", description: "Interaction was updated successfully!" });
				this.props.onChange();
				return this.setState({ modal: false, payees: [], notes: "", interaction: "" });
			})
			.catch(err =>
				notification.error({
					message: "ERROR!",
					description: "Something went wrong.." + err.message,
				})
			);
	};

	saveToFirestore = async (geo: Object, payeesReferences: Array<Object>, notes: string) => {
		const storeData = await firestore.doc(`B2Bs/${this.props.id}`).get();
		const lat = storeData.data()?.coordinates.Ba;
		const lng = storeData.data()?.coordinates.Oa;

		const distance = getDistanceFromLatLonInKm(lat, lng, geo.Ba, geo.Oa);
		const verified = distance <= 0.5;

		return firestore
			.collection("B2Bs/" + this.props.id + "/visits")
			.add({
				notes,
				timestamp: new Date(),
				location: geo,
				distance,
				verified,
				territory: this.props.user.territory ? firestore.doc(`territories/${this.props.user.territory}`) : null,
				territoryName: this.props.user?.territoryName ?? null,
				user: firestore.doc(`users/${this.props.user.uid}`),
				userName: this.props.user.name ?? null,
				payees: payeesReferences.length ? payeesReferences : null,
			})
			.then(async () => {
				firestore.doc("B2Bs/" + this.props.id).update({
					[`${this.props.user.uid}.seen`]: true,
				});
				notification.success({ message: "Success!", description: "Interaction was added successfully!" });
				this.props.onChange();
				return this.setState({ modal: false, payees: [], notes: "", interaction: "" });
			})
			.catch(err =>
				notification.error({
					message: "ERROR!",
					description: "Something went wrong.." + err.message,
				})
			);
	};

	render() {
		const interactionsCol = [
			{
				title: "Date",
				dataIndex: "date",
				// TODO: make dates show time properly
				render: date => date.format("ll"),
				width: 150,
			},
			{
				title: "Notes",
				dataIndex: "notes",
			},
			{
				title: "User",
				dataIndex: "userName",
			},
			{
				title: "Territory",
				dataIndex: "territoryName",
			},
			{
				title: "Contacts",
				dataIndex: "payees",
				width: 200,
				render: payees => {
					if (!payees) {
						return null;
					} else {
						return payees.map(payee => {
							const id = payee.id;
							const name = this.props.payees.find(e => e.key === id);
							return (
								<span>
									<Tag key={id}>{name ? name.payeeName : "N/A"}</Tag>
								</span>
							);
						});
					}
				},
			},
			{
				title: "Actions",
				width: 200,
				render: record => {
					return (
						<>
							{record.date.startOf("day").isSame(moment().startOf("day")) ? (
								<Button
									size={"small"}
									type="primary"
									style={{ marginRight: 6 }}
									onClick={() => {
										this.setState({
											payees: record.payees ? record.payees.map(x => x.id) : [],
											interaction: record.id,
											notes: record.notes,
											modal: true,
										});
									}}>
									Edit
								</Button>
							) : null}
							{record.location && (this.props.user?.role === "Admin" || this.props.user?.role === "Manager") ? (
								<a
									href={`https://www.google.com/maps/search/?api=1&query=${record.location.latitude},${record.location.longitude}`}
									rel="noopener noreferrer"
									target={"_blank"}>
									<Button size={"small"} type="primary">
										View Location
									</Button>
								</a>
							) : null}
						</>
					);
				},
			},
		];

		if (this.props.user.role !== "User") {
			interactionsCol.push({
				width: 200,
				render: r => (
					<Tag color={r.verified ? "green" : "red"}>
						{r.verified ? "Verified" : "Not Verified"} {r.distance ? `(${r.distance.toFixed(2)}km)` : ""}
					</Tag>
				),
			});
		}

		return (
			<>
				<Row gutter={5}>
					<Col span={12}>
						<Typography.Title level={3}>Sales Visits</Typography.Title>
					</Col>
					<Col style={{ textAlign: "right" }} span={12}>
						<Button
							disabled={this.state.data.filter(x => x.date.startOf("day").isSame(moment().startOf("day"))).length > 0}
							onClick={() => {
								this.setState({
									payees: [],
									interaction: "",
									notes: "",
									modal: true,
								});
							}}
							type={"primary"}>
							NEW
						</Button>
					</Col>
				</Row>
				<Table bordered loading={this.state.loading} columns={interactionsCol} pagination={false} scroll={{ y: 340 }} dataSource={this.state.data} />
				{this.state.modal ? (
					<InteractionsModal
						visible={this.state.modal}
						data={this.props.payees}
						payeeSelect={this.state.payees}
						notes={this.state.notes}
						interaction={this.state.interaction}
						handlePayeeChange={e => this.setState({ payees: e })}
						onOk={this.createNewInteraction}
						onCancel={() => this.setState({ modal: false, payees: [], interaction: "", notes: "" })}
					/>
				) : null}
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps)(Visits);
