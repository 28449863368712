import React from "react";
import moment from "moment";

export const columns = (manipulateData: Function, getColumnSearchProps: Function) => [
	{
		title: "Date",
		dataIndex: "date",
		render: (record: string) => <p>{moment(record).format("ll")}</p>,
		// $FlowFixMe
		sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
	},
	{
		title: "Check No.",
		dataIndex: "check",
		...getColumnSearchProps("check"),
	},
	{
		title: "Contact Name",
		dataIndex: "payee_name",
		...getColumnSearchProps("payee_name"),
	},
	// {
	// 	title: "Payee",
	// 	dataIndex: "payee_id",
	// },
	// {
	// 	title: "Purpose",
	// 	dataIndex: "purpose_id",
	// 	render: (record: number) => {
	// 		let purpose;
	//
	// 		switch (record) {
	// 			case 2:
	// 				return (purpose = "B2B Check");
	// 			default:
	// 				return (purpose = null);
	// 		}
	// 	},
	// },
	{
		title: "Amount",
		dataIndex: "amount",
		render: (record: number) => "$" + record,
	},
	{
		title: "Notes",
		dataIndex: "notes",
		...getColumnSearchProps("notes"),
		width: 500,
	},
];
