import React from "react";
// noinspection ES6CheckImport
import { Draggable } from "react-beautiful-dnd";
import { Typography } from "antd";
import { Link } from "react-router-dom";

type Provided = {
	placeholder: any,
	droppableProps: any,
	draggableProps: any,
	dragHandleProps: {
		style: any,
	},
	innerRef: any,
};

type Snapshot = {
	isDragging: boolean,
	isDraggingOver: boolean,
};

// const status = (item: Object) => {
// 	function save(status: boolean | null) {
// 		return firestore.doc("B2Bs/" + item.id).update({
// 			seen: status,
// 		});
// 	}
// 	return (
// 		<Row gutter={[10, 10]}>
// 			<Col span={24}>
// 				<div onClick={() => save(true)} className={"status"} style={{ backgroundColor: "#0985E6", color: "#FFF" }}>
// 					Seen
// 				</div>
// 			</Col>
// 			{/*<Col span={24}>*/}
// 			{/*	<div onClick={() => save(false)} className={"status"} style={{ backgroundColor: "#EB2516", color: "#FFF" }}>*/}
// 			{/*		Not Seen*/}
// 			{/*	</div>*/}
// 			{/*</Col>*/}
// 			<Col span={24}>
// 				<div onClick={() => save(null)} className={"status"}>
// 					Pending
// 				</div>
// 			</Col>
// 		</Row>
// 	);
// };

export const show = (provided: Provided, snapshot: Snapshot, data: Array<Object>) => {
	const day = provided.droppableProps["data-rbd-droppable-id"];
	return (
		<div ref={provided.innerRef} style={getListStyle()}>
			<Typography.Title level={4} style={{ textAlign: "center" }}>
				{day.toUpperCase()}
			</Typography.Title>
			{data.map((item, index) => (
				<Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!item.content}>
					{(provided: Provided, snapshot: Snapshot) => {
						if (item.content) {
							return (
								// <Popover content={status(item)} title={"Change Status"}>
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.seen)}>
									<Link to={"/store/" + item.id} style={{ color: item.seen === null ? "#222" : "#fff" }}>
										{item.content}
									</Link>
								</div>
								// </Popover>
							);
						} else {
							return (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item.seen)}
								/>
							);
						}
					}}
				</Draggable>
			))}
			{provided.placeholder}
		</div>
	);
};

// Function to help us with reordering the result
export const reorder = (list: any, startIndex: number, endIndex: number) => {
	const result: Array<any> = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

// Moves an item from one list to another list.
export const move = (source: Object, destination: Object, droppableSource: Object, droppableDestination: Object) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: Object, seen: boolean | null) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	margin: `0 0 ${grid}px 0`,
	textAlign: "center",
	height: 54,

	// change background colour if dragging
	// background: isDragging ? "#CCC" : "#EEE",
	background: seen === true ? "#0985E6" : seen === false ? "#EB2516" : "#EEE",

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = () => ({
	background: "white",
	padding: grid,
	width: 250,
});
