type GET_REGIONS = {
	type: "GET_REGIONS",
	payload: Array<{
		id: string,
		name: string,
		territories: Array<string>,
	}>,
};

type REMOVE_REGIONS = {
	type: "REMOVE_REGIONS",
};

const regionsReducer = (state: Array<Object> = [], action: GET_REGIONS | REMOVE_REGIONS): Array<Object> => {
	switch (action.type) {
		case "GET_REGIONS":
			return action.payload;
		case "REMOVE_REGIONS":
			return [];
		default:
			return state;
	}
};

export default regionsReducer;
