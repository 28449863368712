import React from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { firestore, geoPoint } from "../../firebase";
import { Button, Col, notification, Row } from "antd";
import Table from "../../components/smartTable";
import AddB2bModal from "./add";
import type { Props, State } from "./types";
import Status from "./status";
import getStores from "../../redux/actions/getStores";
import Type from "./type";

class AllStoresPage extends React.Component<Props, State> {
	constructor() {
		super();

		this.state = {
			type: "",
			company: "",
			city: "",
			address: "",
			territory: "",
			zip: "",
			data: [],
			isLoading: true,
			isVisible: false,
			editing: false,
			total: 0,
			docID: "",
			filteredTerritory: "",
			searchedText: "",
			searchedColumn: "",
		};
	}

	componentDidMount(): void {
		this.props.getB2Bs();
		this.setState({ total: this.props.b2bs.length });
	}

	onCancel = () => {
		this.setState({ isVisible: !this.state.isVisible });
		setTimeout(() => {
			this.setState({
				company: "",
				city: "",
				address: "",
				territory: "",
				zip: "",
				editing: false,
				docID: "",
			});
		}, 1000);
	};

	saveStore = async () => {
		const address = `${this.state.address} ${this.state.city} ${this.state.zip}`;
		const res = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
			params: {
				key: "AIzaSyBu4cNov9LhK1akRw8KEK3LnrzT7194RTk",
				address,
			},
		});

		const location = res.data.results?.[0]?.geometry?.location;
		const coordinates = location ? geoPoint(location.lat, location.lng) : null;

		if (!this.state.editing) {
			if (!this.state.company || !this.state.city || !this.state.address || !this.state.zip) {
				return notification.error({ message: "Whoops!", description: "You need to fill out the form before submitting." });
			}
			return firestore
				.collection("B2Bs")
				.add({
					type: this.state.type,
					company: this.state.company,
					city: this.state.city,
					address: this.state.address,
					coordinates,
					territory: this.state.territory
						? firestore.doc("territories/" + this.state.territory)
						: firestore.doc("territories/" + this.props.user.territory),
					territoryName: this.props.territories.find(x => x.id === this.state.territory ?? this.props.user.territory)?.name,
					zip: this.state.zip,
					timestamp: new Date(),
				})
				.then(() => this.setState({ isVisible: !this.state.isVisible }))
				.then(() => notification.success({ message: "Success!", description: "Store was saved successfully!" }))
				.then(() => this.props.getB2Bs())
				.catch(err => notification.error({ message: "ERROR!", description: err }));
		} else {
			return firestore
				.doc("B2Bs/" + this.state.docID)
				.update({
					type: this.state.type,
					company: this.state.company,
					city: this.state.city,
					address: this.state.address,
					coordinates,
					territory: firestore.doc("territories/" + this.state.territory),
					territoryName: this.props.territories.find(x => x.id === this.state.territory ?? this.props.user.territory)?.name,
					zip: this.state.zip,
					timestamp: new Date(),
				})
				.then(() => this.setState({ isVisible: !this.state.isVisible }))
				.then(() => notification.success({ message: "Success!", description: "Store was updated successfully!" }))
				.then(() => this.props.getB2Bs())
				.catch(err => notification.error({ message: "ERROR!", description: err }));
		}
	};
	handleFilterChange = (pagination: number, filters: string, sorter: any, extra: Object) => {
		this.setState({
			filteredTerritory: filters,
			total: extra.currentDataSource.length,
		});
	};

	render() {
		const territoriesFilter = this.props.territories.map(x => {
			return { text: x.name, value: x.id };
		});
		const columns = [
			{
				title: "Company",
				dataIndex: "company",
				sorter: (a, b) => a.company.localeCompare(b.company),
				search: "company",
				render: (text, item) => <Link to={`/store/${item.id}`}>{text}</Link>,
			},
			{
				title: "Territory",
				filters: this.props.user.role !== "User" ? territoriesFilter : [],
				onFilter: (value, record) => record.territory === value,
				render: key => key.territoryName,
			},
			{
				title: "Address",
				dataIndex: "address",
				search: "address",
			},
			{
				title: "Type",
				dataIndex: "type",
				width: 200,
				filters: [
					{
						text: "Finance",
						value: "Finance",
					},
					{
						text: "Non Finance",
						value: "Non Finance",
					},
				],
				onFilter: (value, record) => record.type === value,
				render: (item, type) => <Type store={type} />,
			},
			{
				title: "Status",
				key: "status",
				width: 200,
				filters: [
					{
						text: "Active",
						value: true,
					},
					{
						text: "Disabled",
						value: false,
					},
				],
				onFilter: (value, record) => {
					if (value === true) {
						return record.active === true || record.active === undefined;
					} else {
						return record.active === false;
					}
				},
				render: (item, store) => <Status store={store} />,
			},
			{
				title: "Action",
				dataIndex: "key",
				key: "action",
				width: 200,
				render: (key, item) => (
					<Button
						onClick={() =>
							this.setState(
								{
									type: item.type,
									company: item.company,
									city: item.city,
									address: item.address,
									territory: item.territory,
									zip: item.zip,
									docID: item.id,
									editing: true,
								},
								() => this.setState({ isVisible: !this.state.isVisible })
							)
						}>
						Edit
					</Button>
				),
			},
		];
		return (
			<>
				<div className="">
					<AddB2bModal
						visible={this.state.isVisible}
						edit={this.state.editing}
						title={!this.state.editing ? "Add B2B Store" : "Update B2B Store"}
						state={this.state}
						onChange={(e: Object) => this.setState({ [e.target.name]: e.target.value })}
						onOk={this.saveStore}
						onOkayText={"Save"}
						territories={this.props.territories}
						onCancel={this.onCancel}
					/>
					<Row type={"flex"}>
						<Col span={12}>
							<h1>({this.state.total}) B2B Stores</h1>
						</Col>
						<Col style={{ textAlign: "right" }} span={12}>
							<Button onClick={() => this.setState({ isVisible: !this.state.isVisible })} type={"primary"}>
								New
							</Button>
							<CSVLink
								style={{ marginLeft: 5 }}
								data={this.props.b2bs.map(x => ({
									company: x.company,
									territories: x.territories,
									address: x.address,
									type: x.type,
									status: x.active === false ? "Disabled" : "Active",
								}))}
								filename={"b2b-stores.csv"}>
								<Button type="primary">Download</Button>
							</CSVLink>
						</Col>
					</Row>
					<Table
						bordered
						columns={columns}
						rowKey={record => record.id}
						dataSource={this.props.b2bs}
						onRowsChange={total => this.setState({ total })}
					/>
				</div>
			</>
		);
	}
}

const mapStateToProps: Object = state => {
	return {
		zips: state.user.zips,
		b2bs: state.b2bs,
		user: state.user,
		territories: state.territories,
	};
};

const mapDispatchToProps: Function = dispatch => {
	return {
		getB2Bs: () => dispatch(getStores()),
	};
};

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AllStoresPage);
