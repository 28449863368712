import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Table, Typography } from "antd";
import axios from "axios";
import { columns } from "./columns";
import CheckLogsModal from "./modal";
import { url } from "./config";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";

const CheckLogs = () => {
	const [state, setState] = useState({
		checks: [],
		loading: false,
		visible: false,
		data: null,
		edit: null,
		searchText: "",
		searchedColumn: "",
	});

	const user = useSelector(state => state.user);

	const onComponentMountAsync = () => {
		setState({ ...state, loading: true, visible: false, edit: null });
		axios
			.get(url, { params: { territory: user.territory ? [user.territory] : user.regionTerritories ? user.regionTerritories.map(item => item.id) : [] } })
			.then(res => {
				return setState({ ...state, data: res.data.filter(x => x["purpose_id"] === 2), loading: false, edit: false, visible: false });
			})
			.catch();
	};

	useEffect(onComponentMountAsync, []);

	const getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
				<>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						Search
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]
				?.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		render: text =>
			state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[state.searchText]}
					autoEscape
					textToHighlight={text?.toString()}
				/>
			) : (
				text
			),
	});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setState({
			...state,
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	const handleReset = clearFilters => {
		clearFilters();
		setState({ ...state, searchText: "" });
	};

	const manipulateData = (data, option) => {
		if (option === "edit") {
			return setState({ ...state, edit: data, visible: true });
		}
		if (option === "delete") {
			axios.delete(`${url}/${data.id}`);
			return onComponentMountAsync().catch();
		}
		return null;
	};

	const close = () => {
		setState({ ...state, edit: null, visible: false });
	};

	return (
		<>
			<CheckLogsModal
				visible={state.visible}
				edit={state.edit}
				close={() => {
					close();
					onComponentMountAsync().catch();
				}}
			/>
			<Row style={{ display: "flex", alignItems: "center" }}>
				<Col span={12}>
					<Typography.Title level={1}>Check Logs</Typography.Title>
				</Col>
				<Col style={{ textAlign: "right" }} span={12} />
			</Row>
			<Table rowKey={({ id }) => id} loading={state.loading} dataSource={state.data} columns={columns(manipulateData, getColumnSearchProps)} />
		</>
	);
};

export default CheckLogs;
