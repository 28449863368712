import React from "react";
import axios from "axios";
import moment from "moment";
import { Row, Col, Button, Typography } from "antd";
import { CSVLink } from "react-csv";

import SmartTable from "../../components/smartTable";

export default () => {
	const [loading, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		axios
			.post("https://us-central1-digital-loans.cloudfunctions.net/referralsReports-B2BLoans", {
				data: {},
			})
			.then(res => {
				setData(res.data.result);
				return setLoading(false);
			})
			.catch();
	}, []);

	return (
		<>
			<Row justify={"space-between"}>
				<Col>
					<Typography.Title style={{ marginBottom: 0 }}>Loans</Typography.Title>
				</Col>
				<Col style={{ paddingTop: 8 }}>
					<CSVLink
						data={data.map(x => ({
							...x,
							time: moment(x.date).format("HH:mm"),
							date: moment(x.date).format("MM/DD/YY"),
						}))}
						filename={"loans.csv"}>
						<Button>Download CSV</Button>
					</CSVLink>
				</Col>
			</Row>
			<br />
			<SmartTable
				rowKey={e => e.id}
				loading={loading}
				pagination={{ defaultPageSize: 100 }}
				dataSource={data}
				style={{ minHeight: "72vh" }}
				columns={[
					{
						title: "Date",
						dataIndex: "date",
						render: d => moment(d).format("lll"),
					},
					{
						title: "Customer's Name",
						dataIndex: "name",
					},
					{
						title: "Customer's Phone",
						dataIndex: "phone",
					},
					{
						title: "B2B Contact",
						dataIndex: "payee",
					},
					{
						title: "B2B Store Front",
						dataIndex: "store",
					},
					{
						title: "B2B Store Front Address",
						dataIndex: "storeAddress",
					},
					{
						title: "B2B Store Zip Code",
						dataIndex: "storeZip",
					},
					{
						title: "Territory",
						dataIndex: "territory",
					},
					{
						title: "Status",
						dataIndex: "status",
					},
					{
						title: "Loan ID",
						dataIndex: "infinityLoanID",
					},
					{
						title: "Amount",
						dataIndex: "loanAmount",
						render: t => `$${t}`,
					},
				]}
			/>
		</>
	);
};
