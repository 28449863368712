import React from "react";
import async from "async";
import { Row, Col, Typography, Table, Button, notification, Tag } from "antd";
import AddTerritoryModal from "./addTerritory";
import { firestore } from "../../firebase";
import { connect } from "react-redux";
import Papa from "papaparse";

import type { Props, State } from "./types";

class Territories extends React.Component<Props, State> {
	state = {
		modalOpen: false,
		loading: false,
		editing: false,
		docID: "",
		zips: [],
		territory: "",
		store: null,
		allTerritories: [],
		csvFile: {},
		inputKey: "",
	};

	componentDidMount(): any {
		return firestore
			.collection("territories")
			.orderBy("name", "asc")
			.onSnapshot(snapshot => {
				const allTerritories = [];
				async.eachOf(
					snapshot.docs,
					(territory, key, callback) => {
						allTerritories.push({
							key: territory.id,
							...territory.data(),
						});
						callback();
					},
					() => this.setState({ allTerritories })
				);
			});
	}

	saveData = async () => {
		await this.setState({ loading: true });
		const newZips = this.state.zips.map(x => parseInt(x));
		if (this.state.editing) {
			await firestore
				.doc("territories/" + this.state.docID)
				.update({
					name: this.state.territory,
					store: this.state.store,
					zips: newZips,
				})
				.then(() => {
					this.setState({
						modalOpen: false,
					});
					return notification.success({ message: "Success!", description: "Territory was updated successfully!" });
				});
		} else {
			await firestore
				.collection("territories")
				.add({
					name: this.state.territory,
					store: this.state.store,
					zips: newZips,
				})
				.then(() => {
					this.setState({
						modalOpen: false,
					});
					return notification.success({ message: "Success!", description: "Territory was created successfully!" });
				});
		}
	};

	deleteTerritory = () => {
		return firestore
			.doc("territories/" + this.state.docID)
			.delete()
			.then(() => this.setState({ modalOpen: false }));
	};

	exportToCsv = (zips: Array<number>, name: string) => {
		let csv = "";
		zips.forEach(zip => {
			csv += zip + "\n";
		});
		const hiddenElement = document.createElement("a");
		hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
		hiddenElement.target = "_blank";
		hiddenElement.download = name + ".csv";
		return hiddenElement.click();
	};

	parseCsv = () => {
		Papa.parse(this.state.csvFile, {
			delimiter: ",",
			dynamicTyping: true,
			complete: results => {
				let parsedZips = results.data.filter(x => x[0] !== null);
				async.eachOf(parsedZips, (parsed, key, callback) => {
					if (parsed && !this.state.zips.includes(parsed)) {
						this.setState({ zips: this.state.zips.concat(parsedZips) });
					}
					return callback();
				});
			},
		});
	};

	render() {
		const columns = [
			{
				title: "Territory",
				dataIndex: "name",
				width: 250,
			},
			{
				title: "Zip Codes",
				dataIndex: "zips",
				render: (item, data) => {
					return data.zips.map(zip => {
						return <Tag key={zip}>{zip}</Tag>;
					});
				},
			},
			{
				title: "Actions",
				key: "actions",
				width: 230,
				render: (item, data) => {
					return (
						<Row gutter={10}>
							<Col style={{ textAlign: "right" }} span={8}>
								<Button
									onClick={() =>
										this.setState({
											docID: data.key,
											editing: true,
											territory: data.name,
											zips: data.zips,
											modalOpen: !this.state.modalOpen,
										})
									}>
									Edit
								</Button>
							</Col>
							<Col style={{ textAlign: "left" }} span={12}>
								<Button onClick={() => this.exportToCsv(data.zips, data.name)} type="primary">
									Export to CSV
								</Button>
							</Col>
						</Row>
					);
				},
			},
		];
		return (
			<>
				<AddTerritoryModal
					visible={this.state.modalOpen}
					onOk={() => this.saveData()}
					handleFile={e => this.setState({ csvFile: e.currentTarget.files[0] }, () => this.parseCsv())}
					onZipsChange={e => this.setState({ zips: e })}
					onDelete={this.deleteTerritory}
					onTerritoryChange={e => this.setState({ territory: e.currentTarget.value })}
					onStoreChange={e => this.setState({ store: parseFloat(e.currentTarget.value) })}
					onCancel={() => this.setState({ modalOpen: !this.state.modalOpen, territory: "", zips: [], editing: false, csvFile: "" })}
					state={this.state}
				/>
				<Row>
					<Col span={12}>
						<Typography.Title>Territories</Typography.Title>
					</Col>
					<Col style={{ textAlign: "right", zIndex: 1 }} span={12}>
						<Button type="primary" onClick={() => this.setState({ modalOpen: !this.state.modalOpen })}>
							Create
						</Button>
					</Col>
					<Col>
						<Table dataSource={this.state.allTerritories} columns={columns} bordered />
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = state => ({
	userZips: state.user.zips,
});

//$FlowFixMe
export default connect(mapStateToProps)(Territories);
