import React from "react";
import { connect } from "react-redux";
import { firestore } from "../../firebase";
import { Button, Row, Col, Modal, Select, Popconfirm, Tooltip } from "antd";
import { EditFilled, DeleteFilled, MobileFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import async from "async";
import moment from "moment";
import EditModal from "./payee";
import type { Props, State } from "./types";
import SmartTable from "../../components/smartTable";
import axios from "axios";

class Payees extends React.Component<Props, State> {
	constructor() {
		super();

		//$FlowFixMe
		this.subscribed = false;
	}
	state = {
		data: [],
		exportableData: [],
		b2bSelected: null,
		cityFilter: [],
		selectedStore: {},
		modal: false,
		editModal: false,
		isLoading: true,
		buttonLoading: false,
		queryStatus: null,
	};
	async componentDidMount(): any {
		await this.fetchPayees();
		this.cityFilter();
	}

	fetchPayees = async () => {
		let payeesRef = await firestore.collection("payees").get();
		await this.setState({
			payees: payeesRef.docs
				.filter(x => !x.data().archived)
				.map(x => {
					return {
						...x.data(),
						id: x.id,
						zip: x.data().zip ? Number(x.data().zip) : x.data().b2bZip ? Number(x.data().b2bZip) : null,
						lastActive: x.data().lastActive ? moment(x.data().lastActive.toDate()).valueOf() : 0,
						lastSignInTime: x.data().lastSignInTime ? moment(x.data().lastSignInTime.toDate()).valueOf() : 0,
						b2b: {
							name: x.data().b2bName ? x.data().b2bName : "",
							id: x.data().b2b ? x.data().b2b.id : "",
						},
					};
				}),
		});
		await this.filterData();
	};

	filterData = async () => {
		const territory = this.state.useTerritory;
		const stores = territory && territory.length ? this.props.b2bs.filter(x => territory.includes(x.territory)) : this.props.b2bs;
		let zips = [];

		if (territory && territory.length) {
			territory.forEach(t => {
				zips = zips.concat(this.props.territories.find(territory => territory.id === t).zips);
			});
		} else {
			zips = this.props.user.zips;
		}

		if (this.state.queryStatus === "my payees") {
			// Show my payees based on B2B id
			const myPayees = this.state.payees.filter(payee => stores.filter(store => store.id === payee.b2b.id).length);
			this.setState({
				isLoading: false,
				data: myPayees,
				exportableData: myPayees,
				buttonLoading: false,
			});
		} else if (this.state.queryStatus === "unassigned payees") {
			// Show al payees with my zip and no store attached
			const myUnassigned = this.state.payees.filter(payee => zips.includes(payee.zip) && payee.b2b.id === "");
			this.setState({
				isLoading: false,
				data: myUnassigned,
				exportableData: myUnassigned,
				buttonLoading: false,
			});
		} else if (this.state.queryStatus === "all payees") {
			// Show all Payees with no store attached
			const allUnassigned = this.state.payees.filter(payee => payee.b2b.id === "");
			this.setState({
				isLoading: false,
				data: allUnassigned,
				exportableData: allUnassigned,
				buttonLoading: false,
			});
		} else {
			// Show all payees with my zip
			const myUnassigned = this.state.payees.filter(payee => zips.includes(payee.zip));
			this.setState({
				isLoading: false,
				data: myUnassigned,
				exportableData: myUnassigned,
				buttonLoading: false,
			});
		}
	};

	cityFilter = () => {
		let city = [];
		async.eachOf(
			this.state.data,
			(payee, key, callback) => {
				city.push({
					text: payee.city ? payee.city : "No City",
					value: payee.city ? payee.city : "No City",
				});
				callback();
			},
			() => this.setState({ cityFilter: city.filter((item, index, self) => index === self.findIndex(t => t.text === item.text)) })
		);
	};

	start = async () => {
		await this.setState({ isLoading: true, modal: false });
		await firestore.doc("payees/" + this.state.b2bSelected).update({
			b2b: this.state.selectedStore.id ? firestore.doc("B2Bs/" + this.state.selectedStore.id) : null,
			b2bName: this.state.selectedStore.name,
			b2bZip: this.state.selectedStore.zip,
			territory: this.state.selectedStore.territory ? firestore.doc("territories/" + this.state.selectedStore.territory) : null,
			territoryName: this.state.selectedStore.territoryName,
		});

		await this.setState({ isLoading: false, b2bSelected: null });
		await this.fetchPayees();
	};

	render() {
		let title = "My B2B Contacts";
		// if (queryStatus === "unassigned payees") {
		// 	title = "My Unassigned B2B Contacts";
		// }
		// if (queryStatus === "app downloads") {
		// 	title = "My App Downloads";
		// }
		// if (queryStatus === "my payees") {
		// 	title = "My B2B Contacts";
		// }
		// if (queryStatus === "all payees") {
		// 	title = "All Unassigned B2B Contacts";
		// }
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				width: 380,
				sorter: (a, b) => a.name.localeCompare(b.name),
				search: "name",
			},
			{
				title: "Email",
				dataIndex: "email",
				sorter: (a, b) => a?.email?.localeCompare(b?.email),
			},
			{
				title: "Phone",
				dataIndex: "phone",
				sorter: (a, b) => a?.phone?.localeCompare(b?.phone),
				search: "phone",
			},
			{
				title: "First Login",
				dataIndex: "lastSignInTime",
				dateRange: "lastSignInTime",
				sorter: (a, b) => a.lastSignInTime - b.lastSignInTime,
				render: date => (date ? moment(date).format("lll") : ""),
			},
			{
				title: "Last Login",
				dataIndex: "lastActive",
				dateRange: "lastActive",
				sorter: (a, b) => a.lastActive - b.lastActive,
				render: date => (date ? moment(date).fromNow() : ""),
			},
			// {
			// 	title: "MSA",
			// 	dataIndex: "city",
			// 	filters: this.state.cityFilter,
			//
			// 	onFilter: (value, record) => record.city.indexOf(value) === 0,
			// 	sorter: (a, b) => a.city.localeCompare(b.city),
			// },
			// {
			// 	title: "Assignation",
			// 	dataIndex: "b2b",
			// 	filters: [
			// 		{
			// 			text: "Pending",
			// 			value: "Pending",
			// 		},
			// 		{
			// 			text: "Completed",
			// 			value: "Completed",
			// 		},
			// 	],
			// 	onFilter: (value, record) => record.value && value.id === "Completed",
			// 	render: b2b => <Tag color={b2b.id ? "green" : "gold"}>{b2b.id ? "Completed" : "Pending"}</Tag>,
			// },
			{
				title: "Store Front",
				dataIndex: "b2b",
				filters: [
					{
						text: "Assigned",
						value: true,
					},
					{
						text: "Unassigned",
						value: false,
					},
				],
				onFilter: (value, record) => (value ? record.b2b.name : !record.b2b.name),
				sorter: (a, b) => a.b2b.name.localeCompare(b.b2b.name),
				render: b2b => (b2b.id ? <Link to={"/store/" + b2b.id}>{b2b.name}</Link> : ""),
			},
			{
				width: 200,
				render: entry => {
					return (
						<>
							<Tooltip title="Edit Contact">
								<Button
									icon={<EditFilled />}
									size={"small"}
									shape={"circle"}
									type={"primary"}
									style={{ marginRight: 5 }}
									onClick={() => {
										this.setState({ editModal: entry.id });
									}}
								/>
							</Tooltip>
							{entry.phone && (
								<Tooltip title={"Text download link"}>
									<Popconfirm
										title="Text a download link to this contact?"
										onConfirm={async () => {
											await axios({
												method: "GET",
												url: "https://b2b.discount-loans.com/payee/" + entry.id + "/text",
												// url: "http://localhost:5000/digital-loans/us-central1/b2b/payee/" + entry.id + "/text",
											});
										}}
										okText="Yes"
										cancelText="No">
										<Button icon={<MobileFilled />} size={"small"} shape={"circle"} type={"primary"} style={{ marginRight: 5 }} />
									</Popconfirm>
								</Tooltip>
							)}
							<Tooltip title="Delete Contact">
								<Popconfirm
									disabled={!entry.b2b.id}
									title="Are you sure you want to archive this B2B Contact?"
									onConfirm={async () => {
										await firestore.doc("payees/" + entry.id).update({
											archived: true,
										});
										await this.fetchPayees();
									}}
									okText="Yes"
									cancelText="No">
									<Button icon={<DeleteFilled />} size={"small"} shape={"circle"} type={"danger"} style={{ marginRight: 5 }} />
								</Popconfirm>
							</Tooltip>
						</>
					);
				},
			},
		];

		return (
			<>
				<Row>
					<Col span={6}>
						<h1>
							{this.state.data.length} {title}
						</h1>
					</Col>
					<Col span={18} style={{ textAlign: "right" }}>
						{this.props.role === "Admin" || this.props.role === "Manager" ? (
							<Select
								mode="multiple"
								placeholder={"Select Territory"}
								style={{ width: 380, marginRight: 10, textAlign: "left" }}
								onChange={async e => {
									await this.setState({ useTerritory: e });
									await this.filterData();
								}}>
								{this.props.territories.map(territory => (
									<Select.Option key={territory.id} value={territory.id} className="year-item">
										{territory.name}
									</Select.Option>
								))}
							</Select>
						) : null}
						{/*<Radio.Group defaultValue={this.state.queryStatus} buttonStyle="solid">*/}
						{/*	/!*<Radio.Button*!/*/}
						{/*	/!*	value={"unassigned payees"}*!/*/}
						{/*	/!*	onClick={() => this.setState({ queryStatus: "unassigned payees", isLoading: true }, this.filterData)}>*!/*/}
						{/*	/!*	My Unassigned B2B Contacts*!/*/}
						{/*	/!*</Radio.Button>*!/*/}
						{/*	/!*<Radio.Button value={"all payees"} onClick={() => this.setState({ queryStatus: "all payees", isLoading: true }, this.filterData)}>*!/*/}
						{/*	/!*	All Unassigned B2B Contacts*!/*/}
						{/*	/!*</Radio.Button>*!/*/}
						{/*	<Radio.Button value={"my payees"} onClick={() => this.setState({ queryStatus: "my payees", isLoading: true }, this.filterData)}>*/}
						{/*		My B2B Contacts*/}
						{/*	</Radio.Button>*/}
						{/*</Radio.Group>*/}
						<Button type="primary" onClick={() => this.setState({ editModal: true })}>
							New Contact
						</Button>
						<CSVLink
							style={{ marginLeft: 5 }}
							data={this.state.exportableData.map(x => ({
								Name: x.name,
								Email: x.email,
								Phone: x.phone,
								"First Login": x.lastSignInTime ? moment(x.lastSignInTime).format("lll") : "",
								"Last Active": x.lastActive ? moment(x.lastActive).format("lll") : "",
								"Work Zip": x.zip ? x.zip : x.b2bZip,
								Assignation: x.b2b.id ? "Completed" : "Pending",
								B2B: x.b2b.name,
								Territory: x.territoryName,
							}))}
							filename={"b2b-contacts.csv"}>
							<Button type="primary">Download</Button>
						</CSVLink>
					</Col>
				</Row>
				<Modal
					title="Assign B2B Contact to Store Front"
					visible={this.state.modal}
					onOk={this.start}
					onCancel={() => this.setState({ modal: false, selectedStore: { id: null, name: null } })}
					okText="Save"
					cancelText="Cancel">
					<b>Select a Store Front</b>
					<br />
					<Select
						style={{ width: "100%" }}
						onChange={e => {
							if (e === null) {
								return this.setState({ selectedStore: { id: e, name: e } });
							}
							const store = this.props.b2bs.find(x => x.id === e);
							// $FlowFixMe
							return this.setState({
								selectedStore: {
									id: e,
									territory: store.territory,
									territoryName: store.territoryName,
									name: store.company ?? null,
									zip: store.zip,
								},
							});
						}}>
						{this.props.b2bs.map(store => {
							return (
								<Select.Option key={store.id} value={store.id}>
									{store.company} ({store.address})
								</Select.Option>
							);
						})}
					</Select>
				</Modal>
				<EditModal
					id={this.state.editModal}
					done={this.fetchPayees}
					close={reload => {
						this.setState({ editModal: false });
						if (reload) {
							this.fetchPayees().catch();
						}
					}}
				/>
				<SmartTable bordered columns={columns} loading={this.state.isLoading} dataSource={this.state.data} rowKey={"id"} />
			</>
		);
	}
}

const mapStateToProps: Object = state => {
	return {
		territory: state.user.territory,
		user: state.user,
		b2bs: state.b2bs,
		role: state.user.role,
		territories: state.territories,
	};
};
//$FlowFixMe
export default connect(mapStateToProps)(Payees);
