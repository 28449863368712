import React from "react";
import axios from "axios";
import { Button, Modal, Input, Typography } from "antd";

type Props = {
	id: null | string,
	notes: null | string,
	applicationNotes: null | string,
	close: Function,
};

function Notes(props: Props) {
	const [loading, setLoading] = React.useState(false);
	const [notes, setNotes] = React.useState(props.notes);

	React.useEffect(() => {
		setNotes(props.notes);
	}, [props.notes]);

	const save = async () => {
		await setLoading(true);
		await axios({
			url: "https://b2b.discount-loans.com/updateReferral",
			params: {
				id: props.id,
				notes,
			},
		});
		await props.ok();
		await props.close();
		await setLoading(false);
	};

	return (
		<Modal
			visible={Boolean(props.id)}
			title={"Notes"}
			footer={[
				<Button key={"close"} type={"danger"} onClick={props.close}>
					Close
				</Button>,
				<Button key={"save"} loading={loading} type={"primary"} onClick={save}>
					Save
				</Button>,
			]}
			onCancel={props.close}>
			{props.applicationNotes ? (
				<>
					<Typography.Text>Application Notes</Typography.Text>
					<Input.TextArea rows={6} value={props.applicationNotes} readOnly disabled />
					<br />
					<br />
				</>
			) : null}
			<Typography.Text>Referral Notes</Typography.Text>
			<Input.TextArea rows={6} value={notes} onChange={e => setNotes(e.target.value)} />
		</Modal>
	);
}

export default Notes;
