import React from "react";
import async from "async";
import moment from "moment";
import { Calendar, Select, Row, Col, Modal, DatePicker, Input, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { firestore } from "../../firebase";
import { connect } from "react-redux";
import { getListData } from "./getListData";
import Cardbox from "../../components/cardbox";
import type { Props, State } from "./types";

class CalendarView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		//$FlowFixMe
		this.subscribed = false;
	}
	state = {
		appointments: [],
		users: [],
		user: this.props.user.uid,
		modal: false,
		adding: false,
		editing: false,
		id: null,
		date: null,
		title: null,
		notes: null,
	};

	async componentDidMount(): any {
		//$FlowFixMe
		this.subscribed = true;
		this.getUsers();
		this.getUsersAppointments();
	}

	componentWillUnmount(): void {
		//$FlowFixMe
		this.subscribed = false;
	}

	getUsers = async () => {
		const users: { docs: Array<Object> } = await firestore.collection("users").get();
		this.setState({
			users: users.docs.map((x: { data: Function, id: string }) => {
				return {
					...x.data(),
					id: x.id,
				};
			}),
		});
	};

	getUsersAppointments = async () => {
		//$FlowFixMe
		if (this.subscribed) {
			await firestore
				.collection("users/" + this.props.user.uid + "/appointments")
				.orderBy("date", "asc")
				.onSnapshot(snapshot => {
					let appointments = [];
					return async.eachOf(
						snapshot.docs,
						(appointment, key, callback) => {
							appointments.push({
								key: appointment.id,
								title: appointment.data().title,
								notes: appointment.data().notes,
								date: appointment.data().date,
							});
							callback();
						},
						() => this.setState({ appointments })
					);
				});
		}
	};

	getUserAppointments = async (uid: string): any => {
		firestore.collection("users/" + uid + "/appointments").onSnapshot(appointments => {
			this.setState({
				user: uid,
				appointments: appointments.docs.map((x: $npm$firebase$firestore$DocumentSnapshot) => {
					return {
						...x.data(),
						id: x.id,
					};
				}),
			});
		});
	};

	add = () => {
		if (!this.state.title || !this.state.date) {
			return false;
		}
		this.setState({ adding: true });
		return firestore
			.collection("users/" + this.state.user + "/appointments")
			.add({
				title: this.state.title,
				date: moment(this.state.date).toDate(),
				notes: this.state.notes,
			})
			.then(() => {
				return this.setState({
					adding: false,
					modal: false,
					title: null,
					date: null,
					notes: null,
				});
			});
	};

	update = (id: any) => {
		if (!this.state.title || !this.state.date) {
			return false;
		}
		return firestore
			.doc("users/" + this.state.user + "/appointments/" + id)
			.update({
				title: this.state.title,
				date: moment(this.state.date).toDate(),
				notes: this.state.notes,
			})
			.then(() => {
				return this.setState({
					editing: false,
					modal: false,
					title: null,
					date: null,
					notes: null,
				});
			});
	};

	dateCellRender = (value: string) => {
		const listData = getListData(value, this.state.appointments);
		return (
			<ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
				{listData.map(item => (
					<li key={item.key}>
						<Row>
							<Col span={14}>
								<h4
									className="print-font"
									style={{ cursor: "pointer" }}
									color={"blue"}
									onClick={() => {
										this.setState({
											title: item.title,
											date: moment(item.date.toDate()),
											notes: item.notes,
											id: item.key,
											editing: true,
											modal: true,
										});
									}}>
									{item.title}
								</h4>
							</Col>
							<Col style={{ textAlign: "right" }} span={10}>
								<h4
									style={{ cursor: "pointer" }}
									className="print-font"
									color={"blue"}
									onClick={() =>
										this.setState({
											title: item.title,
											date: moment(item.date.toDate()),
											notes: item.notes,
											id: item.key,
											editing: true,
											modal: !this.state.modal,
										})
									}>
									{moment(item.date.toDate()).format("h:mm a")}
								</h4>
							</Col>
						</Row>
					</li>
				))}
			</ul>
		);
	};

	render() {
		return (
			<Cardbox>
				<Calendar
					onSelect={e => {
						const todaysAppointment = this.state.appointments.filter(x =>
							moment(x.date.toDate())
								.startOf("day")
								.isSame(e)
						);
						if (!todaysAppointment.length) {
							this.setState({
								date: e.add(
									moment()
										.add(1, "hour")
										.format("H"),
									"hour"
								),
								modal: true,
							});
						}
					}}
					dateCellRender={this.dateCellRender}
					headerRender={({ value, onChange }) => {
						const start = 0;
						const end = 12;
						const monthOptions = [];

						const current = value.clone();
						const localeData = value.localeData();
						const months = [];
						for (let i = 0; i < 12; i++) {
							current.month(i);
							months.push(localeData.months(current));
						}

						for (let index = start; index < end; index++) {
							monthOptions.push(
								<Select.Option className="month-item" key={`${index}`}>
									{months[index]}
								</Select.Option>
							);
						}
						const month = value.month();

						const year = value.year();
						const options = [];
						for (let i = year - 10; i < year + 10; i += 1) {
							options.push(
								<Select.Option key={i} value={i} className="year-item">
									{i}
								</Select.Option>
							);
						}
						return (
							<div>
								<Row>
									<Col span={12}>
										<h1>
											Calendar
											<Button
												className="remove-print"
												type={"primary"}
												shape={"circle"}
												style={{ position: "relative", bottom: 5, left: 20 }}
												onClick={() => this.setState({ modal: true })}>
												<PlusOutlined />
											</Button>
										</h1>
									</Col>
									<Col span={12} style={{ textAlign: "right" }}>
										{this.props.user.role === "Admin" || this.props.user.role === "Manager" ? (
											<Select
												size={"large"}
												style={{ width: 200, textAlign: "left" }}
												defaultValue={this.state.user}
												placeholder={"Select User"}
												onChange={e => this.getUserAppointments(e)}>
												{this.state.users.map(user => (
													<Select.Option key={user.id} className="year-item">
														{user.name}
													</Select.Option>
												))}
											</Select>
										) : null}
										<Select
											size="large"
											dropdownMatchSelectWidth={false}
											value={String(month)}
											style={{ marginLeft: 10, width: 100, textAlign: "left" }}
											onChange={selectedMonth => {
												const newValue = value.clone();
												newValue.month(parseInt(selectedMonth, 10));
												onChange(newValue);
											}}>
											{monthOptions}
										</Select>
										<Select
											size="large"
											dropdownMatchSelectWidth={false}
											className="my-year-select"
											onChange={newYear => {
												const now = value.clone().year(newYear);
												onChange(now);
											}}
											style={{ marginLeft: 10 }}
											value={String(year)}>
											{options}
										</Select>
									</Col>
								</Row>

								<Modal
									onOk={!this.state.editing ? this.add : () => this.update(this.state.id)}
									onCancel={() => this.setState({ title: "", notes: "", modal: false, editing: false })}
									confirmLoading={this.state.adding}
									visible={this.state.modal}
									title={!this.state.editing ? "Add Appointment" : "Update Appointment"}>
									<Row gutter={10} style={{ marginBottom: 10 }}>
										<Col span={12}>
											Title *
											<Input value={this.state.title} onChange={e => this.setState({ title: e.currentTarget.value })} />
										</Col>
										<Col span={12}>
											Appointment Date *
											<DatePicker
												showTime={{ use12Hours: true }}
												format={"ll h:mm a"}
												value={this.state.date}
												onChange={e => this.setState({ date: e })}
												style={{ width: "100%" }}
											/>
										</Col>
									</Row>
									Notes
									<Input.TextArea value={this.state.notes} rows={5} onChange={e => this.setState({ notes: e.currentTarget.value })} />
								</Modal>
							</div>
						);
					}}
				/>
			</Cardbox>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

// $FlowFixMe
export default connect(mapStateToProps)(CalendarView);
