import moment from "moment";

export const getListData = (value: any, data: any) => {
	return data.filter(x => {
		if (x.date) {
			return moment(x.date ? x.date.toDate() : null)
				.startOf("day")
				.isSame(value.startOf("day"));
		} else {
			return null;
		}
	});
};
