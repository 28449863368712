import { Redirect, Route } from "react-router-dom";
import React from "react";

//$FlowFixMe
const PrivateRoute = ({ component: Component, auth, ...rest }) => {
	return (
		<Route
			//$FlowFixMe
			{...rest}
			render={props => {
				if (auth.uid) {
					//$FlowFixMe
					return <Component {...props} />;
				} else {
					return <Redirect to="/login" />;
				}
			}}
		/>
	);
};

export default PrivateRoute;
