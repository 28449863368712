import React from "react";
import { firestore } from "../../firebase";
import { EditableContext } from "./editableRow";
import { Form, Select } from "antd";
import data from "./data";

type State2 = {
	editing: boolean,
};
type Props2 = {
	record: any,
	editable: boolean,
	dataIndex: any,
	title: string,
	index: any,
	handleSave: Function,
	state: Array<Object>,
	children: any,
};

export default class EditableCell extends React.Component<Props2, State2> {
	state = {
		editing: false,
	};

	toggleEdit = () => {
		const editing = !this.state.editing;
		this.setState({ editing });
	};

	save = (e: number | null, column: string, id: string) => {
		return firestore
			.doc("B2Bs/" + id)
			.update({
				[`${this.props.territory}.${column}`]: e,
			})
			.then(() => {
				return this.setState({ editing: false });
			});
	};

	renderCell = () => {
		const { children, dataIndex, record, state } = this.props;
		const { editing } = this.state;

		return editing ? (
			<Form.Item style={{ margin: 0 }}>
				<Select
					style={{ width: "100%" }}
					defaultValue={record[dataIndex] === 0 ? null : record[dataIndex]}
					onChange={e => this.save(e, dataIndex, record.key)}>
					{data[dataIndex].map(x => {
						if (dataIndex === "defaultStop") {
							const appointmentsInThisDay = state.filter(
								a => a.defaultDay === record.defaultDay && a.defaultWeek === record.defaultWeek && a.defaultStop === x.value
							);
							if (!appointmentsInThisDay.length || record.defaultStop === x.value) {
								return (
									<Select.Option key={x.value} value={x.value}>
										{x.text}
									</Select.Option>
								);
							}
						} else {
							return (
								<Select.Option key={x.value} value={x.value}>
									{x.text}
								</Select.Option>
							);
						}
						return null;
					})}
				</Select>
			</Form.Item>
		) : (
			<div className="editable-cell-value-wrap" style={{ paddingRight: 0 }} onClick={this.toggleEdit}>
				{children}
			</div>
		);
	};

	render() {
		const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props;
		return <td {...restProps}>{editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}</td>;
	}
}
