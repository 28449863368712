import React from "react";
import async from "async";
import { Select, Typography, Row, Col, Button, Modal } from "antd";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { firestore } from "../../firebase";
import Cardbox from "../../components/cardbox";
import { move, reorder, show } from "./helpers";
import type { Props, State } from "./types";

class Weekly extends React.Component<Props, State> {
	state = {
		data: [],
		loading: true,
		stores: [],
		week: 1,
		territory: this.props.territory,
		// Columns
		monday: [],
		tuesday: [],
		wednesday: [],
		thursday: [],
		friday: [],
		saturday: [],
	};

	async componentDidMount(): any {
		if (this.props.territory) {
			this.getTerritoryOrder(this.props.territory);
		} else {
			this.setState({ loading: false });
		}
	}

	getTerritoryOrder = async (territory: string): any => {
		const useTerritory = territory ?? this.state.territory;
		const territoryRef = await firestore.doc("territories/" + useTerritory).get();
		const territoryZips = territoryRef.data().zips;

		const stores = await firestore
			.collection("B2Bs")
			.orderBy("company", "asc")
			.get();
		const territoriesStores = stores.docs.filter(x => x.data().active !== false && territoryZips.includes(x.data().zip));
		await this.setState({
			stores: territoriesStores.map(x => ({
				key: x.id,
				day: x.data()[useTerritory]?.day ?? x.data()[useTerritory]?.defaultDay ?? 0,
				week: x.data()[useTerritory]?.week ?? x.data()[useTerritory]?.defaultWeek ?? 0,
				stop: x.data()[useTerritory]?.stop ?? x.data()[useTerritory]?.defaultStop ?? 0,
				company: x.data().company ? x.data().company : "-",
				seen: x.data()[useTerritory]?.seen === undefined ? null : x.data()[useTerritory]?.seen,
			})),
			territory: useTerritory,
		});
		await this.organize();
	};

	update = (stores: Array<Object>, day: number) => {
		stores.forEach((store, index) => {
			if (store.content) {
				return firestore.doc("B2Bs/" + store.id).update({
					[`${this.state.territory}.day`]: day,
					[`${this.state.territory}.stop`]: index + 1,
					[`${this.state.territory}.seen`]: store.seen,
				});
			}
			return null;
		});
	};

	reset = () => {
		Modal.confirm({
			title: "Please confirm",
			content: "This will restore the routing schedule to default",
			okText: "Reset",
			onOk: async () => {
				const stores = this.state.stores.filter(x => x.week === this.state.week);
				await async.eachOf(
					stores,
					async.asyncify(async store => {
						await firestore.doc("B2Bs/" + store.key).update({
							[`${this.state.territory}.day`]: null,
							[`${this.state.territory}.stop`]: null,
							[`${this.state.territory}.seen`]: null,
						});
					})
				);
				await this.getTerritoryOrder();
			},
		});
	};

	organize = async () => {
		const data = {
			monday: [],
			tuesday: [],
			wednesday: [],
			thursday: [],
			friday: [],
			saturday: [],
		};

		const week = this.state.week;
		const stores = this.state.stores;
		const stops = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
		const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
		await async.eachOfSeries(days, (day, dayKey, callback) => {
			async.eachOfSeries(
				stops,
				(stop, stopKey, callback2) => {
					const filtered = stores.filter(x => x.stop === stop && x.day === dayKey + 1 && x.week === week);
					if (filtered.length) {
						data[day].push({
							id: filtered[0].key,
							content: filtered[0].company,
							seen: filtered[0].seen,
						});
					} else {
						data[day].push({
							id: day + stop,
							content: null,
							seen: null,
						});
					}
					callback2();
				},
				() => {
					callback();
				}
			);
		});
		this.setState({
			monday: data.monday,
			tuesday: data.tuesday,
			wednesday: data.wednesday,
			thursday: data.thursday,
			friday: data.friday,
			saturday: data.saturday,
			loading: false,
		});
	};

	changeWeek = (week: number) => {
		this.setState(
			{
				week,
			},
			() => this.organize()
		);
	};

	onDragEnd = (result: any) => {
		const { source, destination } = result;
		const days = {
			monday: 1,
			tuesday: 2,
			wednesday: 3,
			thursday: 4,
			friday: 5,
			saturday: 6,
		};

		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const items = reorder(this.state[source.droppableId], source.index, destination.index);
			this.setState({ [source.droppableId]: items });
			this.update(items, days[source.droppableId]);
		} else {
			const result = move(this.state[source.droppableId], this.state[destination.droppableId], source, destination);
			this.setState({
				[source.droppableId]: result[source.droppableId],
				[destination.droppableId]: result[destination.droppableId],
			});
			this.update(result[source.droppableId], days[source.droppableId]);
			this.update(result[destination.droppableId], days[destination.droppableId]);
		}
	};

	render() {
		return (
			<Cardbox>
				<Row gutter={40}>
					<Col span={12} />
					<Col span={12} style={{ textAlign: "right" }}>
						{this.props.user.role === "Admin" || this.props.user.role === "Manager" ? (
							<Select
								placeholder={"Select Territory"}
								style={{ width: 180, marginRight: 10, textAlign: "left" }}
								onChange={e => this.getTerritoryOrder(e)}>
								{this.props.territories.map(territory => (
									<Select.Option key={territory.id} value={territory.id} className="year-item">
										{territory.name}
									</Select.Option>
								))}
							</Select>
						) : null}
						<Select
							style={{ width: 180, marginRight: 10, textAlign: "left" }}
							value={this.state.week}
							placeholder={"Select Week"}
							onChange={this.changeWeek}>
							<Select.Option value={1}>Week 1</Select.Option>
							<Select.Option value={2}>Week 2</Select.Option>
							<Select.Option value={3}>Week 3</Select.Option>
							<Select.Option value={4}>Week 4</Select.Option>
						</Select>
						<Button type={"primary"} onClick={this.reset}>
							Reset to Default
						</Button>
					</Col>
				</Row>
				<div id={"numbers"}>
					<Typography.Title level={4}>1</Typography.Title>
					<Typography.Title level={4}>2</Typography.Title>
					<Typography.Title level={4}>3</Typography.Title>
					<Typography.Title level={4}>4</Typography.Title>
					<Typography.Title level={4}>5</Typography.Title>
					<Typography.Title level={4}>6</Typography.Title>
					<Typography.Title level={4}>7</Typography.Title>
					<Typography.Title level={4}>8</Typography.Title>
					<Typography.Title level={4}>9</Typography.Title>
					<Typography.Title level={4}>10</Typography.Title>
					<Typography.Title level={4}>11</Typography.Title>
					<Typography.Title level={4}>12</Typography.Title>
					<Typography.Title level={4}>13</Typography.Title>
					<Typography.Title level={4}>14</Typography.Title>
					<Typography.Title level={4}>15</Typography.Title>
					<Typography.Title level={4}>16</Typography.Title>
					<Typography.Title level={4}>17</Typography.Title>
					<Typography.Title level={4}>18</Typography.Title>
					<Typography.Title level={4}>19</Typography.Title>
					<Typography.Title level={4}>20</Typography.Title>
				</div>

				<div id={"dnd"}>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="monday">{(provided, snapshot) => show(provided, snapshot, this.state.monday)}</Droppable>
						<Droppable droppableId="tuesday">{(provided, snapshot) => show(provided, snapshot, this.state.tuesday)}</Droppable>
						<Droppable droppableId="wednesday">{(provided, snapshot) => show(provided, snapshot, this.state.wednesday)}</Droppable>
						<Droppable droppableId="thursday">{(provided, snapshot) => show(provided, snapshot, this.state.thursday)}</Droppable>
						<Droppable droppableId="friday">{(provided, snapshot) => show(provided, snapshot, this.state.friday)}</Droppable>
						<Droppable droppableId="saturday">{(provided, snapshot) => show(provided, snapshot, this.state.saturday)}</Droppable>
					</DragDropContext>
				</div>
			</Cardbox>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		territory: state.user.territory,
		territories: state.territories,
	};
};

// $FlowFixMe
export default connect(mapStateToProps)(Weekly);
