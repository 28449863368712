import React from "react";
import axios from "axios";
import { Form, Modal, Button, Select, Input, InputNumber, Row, Col, Divider } from "antd";
import { firestore } from "../../firebase";
import { useSelector } from "react-redux";

export default props => {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const stores = useSelector(state => state.b2bs);

	React.useEffect(() => {
		const load = async () => {
			if (!props.id || props.id === true) {
				return form.resetFields();
			}
			const data = await firestore.doc("payees/" + props.id).get();
			return form.setFieldsValue({
				name: data.data().name,
				phone: data.data().phone,
				store: data.data().b2b?.id ?? null,
				officePhone: data.data().officePhone,
				email: data.data().email,
				password: data.data().password,
			});
		};
		load().catch();
	}, [form, props.id]);

	const cancel = () => {
		form.resetFields();
		props.close();
	};

	const save = async values => {
		setLoading(true);

		try {
			if (props.id && props.id !== true) {
				await axios({
					method: "POST",
					url: "https://b2b.discount-loans.com/payee/" + props.id,
					// url: "http://localhost:5000/digital-loans/us-central1/b2b/payee/" + props.id,
					data: {
						store: values.store,
						name: values.name,
						phone: values.phone,
						officePhone: values.officePhone,
						email: values.email,
						password: values.password,
					},
				});
			} else {
				await axios({
					method: "POST",
					url: "https://b2b.discount-loans.com/payee",
					// url: "http://localhost:5000/digital-loans/us-central1/b2b/payee",
					data: {
						store: values.store,
						name: values.name,
						phone: values.phone,
						officePhone: values.officePhone,
						email: values.email,
						password: values.password,
					},
				});
			}
			setLoading(false);
			form.resetFields();
			return props.close(true);
		} catch (e) {
			setLoading(false);
			Modal.error({
				title: "Something went wrong",
				content: e.response.data.message,
			});
		}
	};

	return (
		<Modal
			forceRender
			visible={props.id}
			onCancel={cancel}
			title={"B2B Contact"}
			footer={[
				<Button key={"1"} onClick={cancel} type={"danger"}>
					Cancel
				</Button>,
				<Button key={"2"} onClick={form.submit} type={"primary"} loading={loading}>
					Save
				</Button>,
			]}>
			<Form form={form} initialValues={{ email: "" }} onFinish={save} layout={"vertical"}>
				<Row gutter={[10, 0]}>
					<Col span={12}>
						<Form.Item label="B2B Contact's Full Name" name="name" rules={[{ required: true, message: "Name is a required field" }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="B2B Contact's Office Number" name="officePhone" rules={[{ required: true, message: "Phone is a required field" }]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label="B2B Contact's Store" name="store" rules={[{ required: true, message: "Store is a required field" }]}>
							<Select>
								{stores.map(x => {
									return (
										<Select.Option value={x.id}>
											{x.company} - {x.address}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Divider style={{ marginTop: 5 }} />
					<Col span={24}>
						<Form.Item label="B2B Contact's Phone" name="phone">
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="B2B Contact's Email" name="email">
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="B2B Contact's Password" name="password">
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};
