import React from "react";
import async from "async";
import { Button, Table, Row, Col, notification, Popconfirm } from "antd";
import Cardbox from "../../components/cardbox";
import UserModal from "./add";
import { firebaseAdmin, firestore } from "../../firebase";
import { connect } from "react-redux";

import type { Props, State } from "./types";

class Users extends React.Component<Props, State> {
	state = {
		isModalVisible: false,
		name: "",
		email: "",
		password: "",
		confirmPassword: "",
		role: "",
		zips: [],
		region: "",
		territory: "",
		users: null,
		editing: false,
		docID: "",
	};

	componentDidMount(): void {
		this.getAllUsers();
	}

	getAllUsers = () => {
		return firestore.collection("users").onSnapshot(users => {
			let allUsers = [];
			async.eachOf(
				users.docs,
				async (user, key, callback) => {
					let region = user.data().region;
					let territory = user.data().territory;
					allUsers.push({
						key: user.id,
						name: user.data().name,
						email: user.data().email,
						role: user.data().role ? user.data().role : "User",
						region: region ? this.props.regions.find(x => x.id === region.id)?.name : null,
						territory: territory ? this.props.territories.find(x => x.id === territory.id)?.name : null,
					});
					callback();
				},
				() => this.setState({ users: allUsers })
			);
		});
	};

	saveUser = async () => {
		if (this.state.editing) {
			firestore
				.doc(`users/${this.state.docID}`)
				.update({
					name: this.state.name,
					email: this.state.email,
					role: this.state.role,
					password: this.state.password,
					region: this.state.role !== "Manager" ? null : firestore.doc("regions/" + this.state.region),
					territory: this.state.role !== "User" ? null : firestore.doc("territories/" + this.state.territory),
				})
				.then(() => {
					this.setState({
						isModalVisible: !this.state.isModalVisible,
						name: "",
						email: "",
						role: "",
						password: "",
						confirmPassword: "",
						territory: "",
					});
					return notification.success({
						message: "Success!",
						description: "User was updated successfully!",
					});
				})
				.catch(err =>
					notification.success({
						message: "Ooops! Something went wrong.",
						description: err.message,
					})
				);
		} else {
			try {
				const user = await firebaseAdmin.createUserWithEmailAndPassword(this.state.email, this.state.password);
				const userID = user.user.uid;
				await firestore
					.collection("users")
					.doc(userID)
					.set({
						name: this.state.name,
						email: this.state.email,
						password: this.state.password,
						role: this.state.role,
						zips: this.state.zips.map(x => parseInt(x)),
						region: this.state.role !== "Manager" ? null : firestore.doc("regions/" + this.state.region),
						territory: this.state.role !== "User" ? null : firestore.doc("territories/" + this.state.territory),
					})
					.then(() => {
						this.setState({
							isModalVisible: !this.state.isModalVisible,
							name: "",
							email: "",
							role: "",
							password: "",
							confirmPassword: "",
							territory: "",
						});
						return notification.success({
							message: "Success!",
							description: "User was created successfully!",
						});
					})
					.catch(err =>
						notification.error({
							message: "Ooops! Something went wrong",
							description: err.message,
						})
					);
				return await firebaseAdmin.signOut();
			} catch (err) {
				if (err.message) {
					notification.error({
						message: "Ooops! Something went wrong",
						description: err.message,
					});
				}
			}
		}
		return null;
	};

	render() {
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
			},
			{
				title: "Email",
				dataIndex: "email",
			},
			{
				title: "Role",
				dataIndex: "role",
			},
			{
				title: "Area",
				index: "area",
				render: user => {
					if (user.region) {
						return (
							<>
								<b>Region: </b>
								{user.region}
							</>
						);
					} else if (user.territory) {
						return (
							<>
								<b>Territory: </b>
								{user.territory}
							</>
						);
					} else {
						return <b>All</b>;
					}
				},
			},
			{
				title: "Territory",
				dataIndex: "territory",
			},
			{
				title: "Action",
				key: "action",
				render: (i, item) => (
					<Row>
						<Col style={{ textAlign: "center" }} span={12}>
							<Button
								onClick={() =>
									this.setState({
										isModalVisible: !this.state.isModalVisible,
										editing: true,
										name: item.name,
										role: item.role,
										email: item.email,
										territory: item.territory,
										docID: item.key,
									})
								}>
								Edit
							</Button>
						</Col>
						<Col span={12}>
							<Popconfirm
								title={"Are you sure you want to delete this user?"}
								onConfirm={() => {
									return firestore
										.doc(`users/${item.key}`)
										.delete()
										.then(() =>
											notification.success({
												message: "Success!",
												description: "User was deleted successfully!",
											})
										)
										.catch(err =>
											notification.error({
												message: "Ooops! Something went wrong.",
												description: err.message,
											})
										);
								}}
								okText={"Yes"}
								cancelText={"No"}>
								<Button type="danger">Delete</Button>
							</Popconfirm>
						</Col>
					</Row>
				),
			},
		];

		return (
			<Cardbox>
				<UserModal
					title={this.state.editing ? "Update User" : "Create User"}
					isVisible={this.state.isModalVisible}
					onCancel={() =>
						this.setState({
							isModalVisible: !this.state.isModalVisible,
							editing: false,
							name: "",
							role: "",
							email: "",
							password: "",
							territory: "",
							zips: [],
							docID: "",
						})
					}
					zips={this.state.zips}
					onRegionChange={e => this.setState({ region: e })}
					onTerritoryChange={e => this.setState({ territory: e })}
					onRoleChange={e => this.setState({ role: e })}
					onInputChange={e => this.setState({ [e.currentTarget.name]: e.currentTarget.value })}
					saveUser={() => this.saveUser()}
					value={this.state}
				/>
				<Row>
					<Col span={12}>
						<h1>Users</h1>
					</Col>
					<Col style={{ textAlign: "right" }} span={12}>
						<Button type="primary" onClick={() => this.setState({ isModalVisible: !this.state.isModalVisible })}>
							Create User
						</Button>
					</Col>
				</Row>
				<Table columns={columns} dataSource={this.state.users} bordered pagination={false} scroll={{ y: 600 }} />
			</Cardbox>
		);
	}
}

const mapStateToProps = state => {
	return {
		regions: state.regions,
		territories: state.territories,
	};
};

//$FlowFixMe
export default connect(mapStateToProps)(Users);
