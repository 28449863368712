import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Popconfirm, Row, Table, Tag, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import RegionsModal from "./regionsModal";
import getRegions from "../../redux/actions/getRegions";
import { firestore } from "../../firebase";

const Regions = () => {
	const [state, setState] = useState({
		visible: false,
		editableData: null,
		title: "Create region",
	});
	const dispatch = useDispatch();
	const regions = useSelector(state => state.regions);
	const territories = useSelector(state => state.territories);

	useEffect(() => {
		dispatch(getRegions());
	}, [dispatch]);

	const deleteRegion = async id => {
		const getUserTerritories = await firestore
			.collection("users")
			.where("region", "==", firestore.doc("regions/" + id))
			.get();
		const isUsedTerritory = getUserTerritories.docs.map(item => {
			return {
				...item.data(),
			};
		});
		if (isUsedTerritory.length) {
			return Modal.error({ title: "Whoops!", content: "This territory is currently in use by a user." });
		} else {
			await firestore.doc("regions/" + id).delete();
			return dispatch(getRegions());
		}
	};

	const columns = [
		{
			title: "Region",
			dataIndex: "name",
			width: 250,
		},
		{
			title: "Territories",
			dataIndex: "territories",
			render: items => {
				return items.map(item => {
					const territory = territories.filter(x => x.id === item);
					const name = territory.map(item => item.name);
					return <Tag>{name}</Tag>;
				});
			},
		},
		{
			title: "Actions",
			key: "actions",
			width: 230,
			render: data => {
				return (
					<Row>
						<Col span={12}>
							<Button onClick={() => setState({ editableData: data, visible: true, title: "Edit" })} type={"default"}>
								Edit
							</Button>
						</Col>
						<Col span={12}>
							<Popconfirm
								title={"Are you sure you want to delete this region?"}
								onConfirm={() => deleteRegion(data.id)}
								onCancel={() => null}
								okText={"Yes"}
								cancelText={"No"}>
								<Button type={"danger"}>Delete</Button>
							</Popconfirm>
						</Col>
					</Row>
				);
			},
		},
	];
	return (
		<Row>
			<RegionsModal
				visible={state.visible}
				title={state.title}
				key={state.editableData?.id}
				editableData={state.editableData}
				onCancel={() => {
					setState({ ...state, visible: false });
					setTimeout(() => {
						setState({ visible: false, editableData: null, title: "Create region" });
					}, 500);
				}}
			/>
			<Col span={12}>
				<Typography.Title>Regions</Typography.Title>
			</Col>
			<Col style={{ textAlign: "right", zIndex: 1 }} span={12}>
				<Button type="primary" onClick={() => setState({ visible: true })}>
					Create
				</Button>
			</Col>
			<Col span={24}>
				<Table rowKey={item => item.id} dataSource={regions} columns={columns} bordered />
			</Col>
		</Row>
	);
};

export default Regions;
