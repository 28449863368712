import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../../firebase";
import getRegions from "../../redux/actions/getRegions";

const RegionsModal = props => {
	const [state, setState] = useState({ loading: false, editableData: null });
	const dispatch = useDispatch();
	const territories = useSelector(state => state.territories);
	const [form] = Form.useForm();

	const onFinish = async values => {
		setState({ ...state, loading: true });

		const mappedTerritories = values.territories.map(item => {
			return firestore.doc("territories/" + item);
		});
		if (props.title === "Edit") {
			await firestore.doc("regions/" + props.editableData.id).update({ name: values.name, territories: mappedTerritories });
			dispatch(getRegions());
		} else {
			await firestore.collection("regions").add({ name: values.name, territories: mappedTerritories });
			dispatch(getRegions());
		}

		props.onCancel();
		setState({ ...state, loading: false });
		return form.resetFields();
	};
	return (
		<Modal
			visible={props.visible}
			onCancel={() => {
				props.onCancel();
				return form.resetFields();
			}}
			title={props.title}
			footer={[
				<Button key={0} onClick={() => props.onCancel()}>
					Cancel
				</Button>,
				<Button key={1} type={"primary"} loading={state.loading} onClick={form.submit}>
					{props.title === "Edit" ? "Update" : "Create"}
				</Button>,
			]}>
			<Form layout={"vertical"} form={form} onFinish={onFinish} initialValues={props.editableData ?? {}}>
				<Row>
					<Col span={24}>
						<Form.Item label={"Region Name"} name={"name"}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label={"Territories"} name={"territories"}>
							<Select mode={"tags"}>
								{territories.map(item => (
									<Select.Option key={item.id} value={item.id}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default RegionsModal;
