import React, { Component } from "react";
import { Row, Col, Button, Tag, Modal, Select, Tooltip } from "antd";
import Table from "../../components/smartTable";
import LinkStore from "./linkStore";
import LinkPayee from "./linkPayee";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import Add from "./add";
import Assign from "./assign";
import Status from "./status";
import Match from "./match";
import Notes from "./notes";
import get from "./get";
import type { IndexProps, IndexState } from "./types";
import { DeleteFilled, EditFilled, FileTextFilled } from "@ant-design/icons";

class MyComponent extends Component<IndexProps, IndexState> {
	state = {
		loading: true,
		add: false,
		link: false,
		buttonLoading: false,
		linkPayee: false,
		assign: null,
		match: {
			id: null,
			matches: [],
		},
		status: {
			id: null,
			status: null,
			payee: null,
			store: null,
			commission: 0,
		},
		notes: {
			id: null,
			notes: null,
			applicationNotes: null,
		},
		referrals: [],
		referralsCount: 0,
		searchedColumn: "",
		searchText: "",
		searchTerritories: [],
	};

	componentDidMount(): any {
		return this.get();
	}

	get = async () => {
		this.setState({ loading: true, buttonLoading: false, add: false, assign: null });
		const { referrals, filter } = await get(this.state.searchTerritories);

		this.setState({
			referralsFilter: filter,
			referralsCount: referrals.length,
			referrals: referrals.map(item => {
				return {
					...item,
					appStatus: item.matches.length ? "Possible Online Applications Found" : "Has Not Applied Online",
				};
			}),
			loading: false,
		});
	};

	delete = (id: string) => {
		Modal.confirm({
			title: "Please Confirm",
			content: "This will change the status permanently delete the lead",
			okText: "Continue",
			okType: "danger",
			onOk: async () => {
				await axios.get("https://b2b.discount-loans.com/deleteReferral?id=" + id);
				return this.get();
			},
		});
	};

	render() {
		return (
			<>
				<Row style={{ paddingBottom: 10 }} gutter={5}>
					<Col span={12}>
						<h1>
							{this.state.referralsCount} Referrals {this.state.referralsFilter ? `(${this.state.referralsFilter})` : null}
						</h1>
					</Col>
					<Col span={12} style={{ textAlign: "right" }}>
						<Button
							style={{ marginRight: 2.5 }}
							type={"primary"}
							loading={this.state.buttonLoading}
							onClick={() => {
								this.setState({ buttonLoading: true });
								return axios
									.get("https://us-central1-digital-loans.cloudfunctions.net/manualLinkReferrals")
									.then(() => this.get())
									.catch(err => {
										this.setState({ buttonLoading: false });
										return err.message;
									});
							}}>
							Refresh Page
						</Button>

						<Button style={{ marginLeft: 2.5 }} type={"primary"} onClick={() => this.setState({ add: true })}>
							Add Referral
						</Button>
					</Col>
					<Col span={24}>
						{this.props.user.role === "Admin" || this.props.user.role === "Manager" ? (
							<Select
								placeholder={"Select Territory"}
								mode={"multiple"}
								style={{ width: "46%", marginRight: 10, textAlign: "left" }}
								onChange={e => this.setState({ searchTerritories: e })}>
								{this.props.territories.map(territory => (
									<Select.Option key={territory.id} value={territory.id} className="year-item">
										{territory.name}
									</Select.Option>
								))}
							</Select>
						) : null}
						{this.props.user.role === "Admin" || this.props.user.role === "Manager" ? (
							<Button style={{ marginLeft: 5 }} type="primary" onClick={this.get}>
								Search
							</Button>
						) : null}
						<CSVLink
							style={{ marginLeft: 5 }}
							filename={"referrals.csv"}
							data={this.state.referrals.map(x => ({
								id: x.id,
								date: moment(x.created).format("MM/DD/YY"),
								name: x["firstname"],
								origin: x.origin,
								phone: x.phone,
								commission: x["commision"],
								payeeID: x.payeeId,
								payee: x.payee,
								store: x.store,
								status: x.status,
								applicationStatus: x["application_matched"]
									? "Applied Online"
									: x["matches"].length
									? "Possible Online Applications Found"
									: "Has Not Applied Online",
								territory: x.territoryName,
							}))}>
							<Button type="primary">Download</Button>
						</CSVLink>
					</Col>
				</Row>

				<Add visible={this.state.add} ok={this.get} close={() => this.setState({ add: false })} />
				<LinkStore visible={this.state.link} payeeID={this.state.link} ok={this.get} close={() => this.setState({ link: false })} />
				<LinkPayee visible={this.state.linkPayee} referral={this.state.linkPayee} ok={this.get} close={() => this.setState({ linkPayee: false })} />
				{this.state.assign ? (
					<Assign visible={Boolean(this.state.assign)} id={this.state.assign} ok={this.get} close={() => this.setState({ assign: null })} />
				) : null}
				{this.state.match ? (
					<Match
						visible={Boolean(this.state.match.id)}
						referral={this.state.match}
						ok={this.get}
						close={() =>
							this.setState({
								match: {
									id: null,
									name: null,
									phone: null,
									matches: [],
								},
							})
						}
					/>
				) : null}
				{this.state.status ? (
					<Status
						id={this.state.status.id}
						status={this.state.status.status}
						payee={this.state.status.payee}
						store={this.state.status.store}
						origin={this.state.status.origin}
						commission={this.state.status.commission}
						ok={this.get}
						close={() =>
							this.setState({
								status: {
									id: null,
									status: null,
									payee: null,
									store: null,
									commission: 0,
								},
							})
						}
					/>
				) : null}
				{this.state.notes ? (
					<Notes
						id={this.state.notes.id}
						notes={this.state.notes.notes}
						applicationNotes={this.state.notes.applicationNotes}
						ok={this.get}
						close={() =>
							this.setState({
								notes: {
									id: null,
									notes: null,
									applicationNotes: null,
								},
							})
						}
					/>
				) : null}

				<Table
					loading={this.state.loading}
					dataSource={this.state.referrals}
					rowKey={e => e.created}
					onRowsChange={referralsCount => this.setState({ referralsCount })}
					columns={[
						{
							title: "Date",
							dataIndex: "created",
							width: 200,
							defaultSortOrder: "descend",
							sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
							render: text => moment(text).format("lll"),
							dateRange: true,
						},
						{
							title: "Customer's Name",
							dataIndex: "name",
							search: "name",
						},
						{
							title: "Customer's Phone",
							dataIndex: "phone",
						},
						{
							title: "Commission",
							dataIndex: "commission",
							width: 100,
							render: text => "$" + text,
						},
						{
							title: "B2B Contact",
							search: "payee",
							render: record => {
								if (record.origin === "C2B") {
									return "C2B";
								} else if (!record.payee) {
									return (
										<Button style={{ padding: 0 }} type={"link"} onClick={() => this.setState({ linkPayee: record.id })}>
											Link B2B Contact
										</Button>
									);
								}
								return record.payee;
							},
						},
						{
							title: "B2B Store",
							width: 100,
							index: "store",
							sorter: (a, b) => a.store?.localeCompare(b.store),
							render: lead => {
								if (lead.store) {
									return <Link to={"/store/" + lead.storeId}>{lead.store}</Link>;
								} else if (lead.payee) {
									return (
										<Button style={{ padding: 0 }} type={"link"} onClick={() => this.setState({ link: lead.payeeId })}>
											Link Store
										</Button>
									);
								} else {
									return "";
								}
							},
						},
						{
							title: "Status",
							width: 100,
							key: "status",
							filters: [
								{
									text: "PAID",
									value: "PAID",
								},
								{
									text: "PENDING",
									value: "PENDING",
								},
								{
									text: "APPROVED",
									value: "APPROVED",
								},
								{
									text: "LOAN: NO REFERRAL FEE",
									value: "LOAN: NO REFERRAL FEE",
								},
								{
									text: "NO LOAN",
									value: "NO LOAN",
								},
								{
									text: "CONTACTED",
									value: "CONTACTED",
								},
								{
									text: "NO ANSWER",
									value: "NO ANSWER",
								},
							],
							onFilter: (value, record) => record.status.indexOf(value) === 0,
							render: record => {
								const color = () => {
									switch (record.status) {
										case "PAID":
											return "default";
										case "PENDING":
											return "orange";
										case "APPROVED":
											return "green";
										case "NO LOAN":
											return "red";
										default:
											return "purple";
									}
								};

								return (
									<Tag
										color={color()}
										onClick={() => {
											if (record.status === "PENDING" || this.props.user.role !== "User") {
												this.setState({
													status: {
														id: record.id,
														status: record.status,
														payee: record.payee,
														store: record.store,
														origin: record.origin,
														commission: record["commission"],
													},
												});
											}
										}}>
										{record.status}
										{record["requested"] === 1 ? ": Paper Check" : null}
										{record["requested"] === 2 ? ": ECheck" : null}
									</Tag>
								);
							},
						},
						{
							width: 400,
							title: "Application Status",
							filters: [
								{
									text: "Possible Online Applications Found",
									value: "possible-matches",
								},
								{
									text: "Applied Online",
									value: "matches",
								},
								{
									text: "Has Not Applied Online",
									value: "no-matches",
								},
							],
							onFilter: (value, record) => {
								if (value === "no-matches") {
									return !record.matches.length && !record["application_matched"];
								} else if (value === "matches") {
									return record["application_matched"] === 1;
								} else {
									return !record["application_matched"] && record.matches.length;
								}
							},
							render: record => {
								if (record["infinity_customer"]) {
									return (
										<a
											rel="noopener noreferrer"
											target={"_blank"}
											href={
												"https://www.discount-title-loan.com/plm.net/customers/CustomerDetails.aspx?customerid=" +
												record["infinity_customer"]
											}>
											Loan #{record["infinity_loan"]}
										</a>
									);
								} else if (record["application_matched"]) {
									return "Applied Online " + (record["application_step"] ? `(${record["application_step"]})` : "");
								} else if (record.matches.length) {
									return (
										<Button
											type={"link"}
											style={{ padding: 0 }}
											onClick={() => {
												this.setState({
													match: {
														id: record.id,
														name: record.name,
														phone: record.phone,
														matches: record.matches,
													},
												});
											}}>
											Possible Online Applications Found
										</Button>
									);
								} else {
									return "Has Not Applied Online";
								}
							},
						},
						{
							render: record => (
								<>
									{record.status === "PENDING" || this.props.user.role !== "User" ? (
										<Tooltip title={"Edit Status"}>
											<Button
												icon={<EditFilled />}
												type={"link"}
												onClick={() => {
													this.setState({
														status: {
															id: record.id,
															status: record.status,
															payee: record.payee,
															store: record.store,
															origin: record.origin,
															commission: record["commission"],
														},
													});
												}}
											/>
										</Tooltip>
									) : null}
									<Tooltip title={"Edit Notes"}>
										<Button
											icon={<FileTextFilled />}
											type={"link"}
											onClick={() => {
												this.setState({
													notes: {
														id: record.id,
														notes: record.notes,
														applicationNotes: record["application_notes"],
													},
												});
											}}
										/>
									</Tooltip>
									<Tooltip title={"Delete"}>
										<Button
											style={{ color: "red" }}
											icon={<DeleteFilled />}
											type={"link"}
											onClick={() => {
												this.delete(record.id);
											}}
										/>
									</Tooltip>
								</>
							),
						},
					]}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		territories: state.territories,
	};
}

//$FlowFixMe
export default connect(mapStateToProps)(MyComponent);
