import React from "react";
import axios from "axios";
import moment from "moment";
import { Row, Col, Button, Typography } from "antd";
import { CSVLink } from "react-csv";

import SmartTable from "../../components/smartTable";

export default () => {
	const [loading, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		axios
			.post("https://us-central1-digital-loans.cloudfunctions.net/referralsReports-B2BDownloads", {
				data: {},
			})
			.then(res => {
				setData(res.data.result);
				return setLoading(false);
			})
			.catch();
	}, []);

	return (
		<>
			<Row justify={"space-between"}>
				<Col>
					<Typography.Title style={{ marginBottom: 0 }}>Downloads</Typography.Title>
				</Col>
				<Col style={{ paddingTop: 8 }}>
					<CSVLink
						data={data.map(x => ({
							...x,
							created: moment(x.created).format("MM/DD/YY"),
							successfulLogin: x.successfulLogin ? moment(x.successfulLogin).format("MM/DD/YY") : null,
							lastActive: x.lastActive ? moment(x.lastActive).format("MM/DD/YY") : null,
						}))}
						filename={"downloads.csv"}>
						<Button>Download CSV</Button>
					</CSVLink>
				</Col>
			</Row>
			<br />
			<SmartTable
				rowKey={e => e.id}
				loading={loading}
				pagination={{ defaultPageSize: 100 }}
				dataSource={data}
				style={{ minHeight: "72vh" }}
				columns={[
					{
						title: "Name",
						dataIndex: "name",
					},
					{
						title: "Phone",
						dataIndex: "phone",
					},
					{
						title: "Zip",
						dataIndex: "zip",
					},
					{
						title: "Email",
						dataIndex: "email",
					},
					{
						title: "Store",
						dataIndex: "store",
					},
					{
						title: "Territory",
						dataIndex: "territory",
					},
					{
						title: "Leads",
						dataIndex: "leads",
					},
					{
						title: "Loans",
						dataIndex: "loans",
					},
					{
						title: "Created",
						dataIndex: "created",
						render: d => moment(d).format("lll"),
					},
					{
						title: "Successful Login",
						dataIndex: "successfulLogin",
						render: d => (d ? moment(d).format("lll") : null),
					},
					{
						title: "Last Active",
						dataIndex: "lastActive",
						render: d => (d ? moment(d).format("lll") : null),
					},
				]}
			/>
		</>
	);
};
