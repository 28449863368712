import React from "react";
import async from "async";
import axios from "axios";
import { firestore } from "../../firebase";
import { Row, Col, DatePicker } from "antd";

import type { Props, State } from "./types";
import MonthsTable from "../../components/monthsTable/monthsTable";
import Payees from "./payees";
import Visits from "./visits";
import Add from "./addPayee";

import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

class StorePage extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			storeName: "",
			address: "",

			addPayee: false,
			payeeData: [],
			payeesDataFromApi: [],
			loading: true,
			totalData: [],
			dateRange: "",
			interactionsTotal: 0,
			leadsTotal: 0,
			loansTotal: 0,
			monthData: [],
		};
	}

	componentDidMount() {
		return this.load();
	}

	load = async () => {
		await this.setState({ loading: true });
		const { id } = this.props.match.params;
		const docRef: Object = firestore.doc("B2Bs/" + id);
		await this.getStoreName(id);
		await this.fetchB2bIndividualData(docRef);
	};

	fetchB2bIndividualData = async (docRef: string) => {
		const payeesCollection = await firestore
			.collection("payees")
			.where("b2b", "==", docRef)
			.get();

		const payees = payeesCollection.docs.map(x => ({
			...x.data(),
			id: x.id,
			key: x.id,
			payeeName: x.data().name,
		}));

		await this.setState({ payeeData: payees });
		await this.getPayeeData();
	};

	getStoreName = async (id: string) => {
		const storeDoc = await firestore.doc("B2Bs/" + id).get();
		this.setState({ storeName: storeDoc.data().company, address: storeDoc.data().address });
	};

	getPayeeData = async (fromDate?: string, toDate?: string) => {
		const { id } = this.props.match.params;
		let payeesDataFromApi = [];
		let monthData = [];

		await async.eachOfSeries(
			this.state.payeeData,
			async.asyncify(async payee => {
				const mysqlData = await axios.get("https://b2b.discount-loans.com/payee/" + payee.key);
				const userRef = firestore.doc("payees/" + payee.key);
				const userVisits = await firestore
					.collection("B2Bs/" + id + "/visits")
					.where("payees", "array-contains", userRef)
					.get();
				const referrals = mysqlData.data;
				const { payeeName, key } = payee;
				const range = fromDate && toDate ? moment.range(fromDate, toDate) : moment.range();
				let user = {
					key,
					payeeName,
					// ...userData,
					lastActive: payee.lastActive,
					userTotalInteractions: userVisits.docs.filter(x => {
						const date = moment(x.data().timestamp.toDate());
						return range.contains(date);
					}).length,
					userTotalLeads: referrals.filter(x => {
						const date = moment(x.created);
						return range.contains(date);
					}).length,
					userTotalLoans: referrals.filter(x => {
						const date = moment(x.created);
						return range.contains(date) && x.status === 3;
					}).length,
					interactions: userVisits.docs,
					leads: referrals,
				};
				payeesDataFromApi.push(user);
				monthData.push({
					name: user.payeeName,
					interactions: user.interactions.map(y => moment(y.data().timestamp.toDate())),
					leads: user.leads,
				});
			})
		);
		await this.setState({ payeesDataFromApi, monthData, loading: false });
	};

	render() {
		return (
			<>
				<Row gutter={[30, 30]}>
					<Col span={18}>
						<h1 style={{ margin: 0 }}>{this.state.storeName}</h1>
						<h2>({this.state.address})</h2>
					</Col>
					<Col span={6}>
						<br />
						<DatePicker.RangePicker style={{ width: "100%" }} size="medium" onChange={e => this.getPayeeData(e[0], e[1])} />
					</Col>
					<Col span={24}>
						<Payees state={this.state} onAdd={() => this.setState({ addPayee: true })} />
					</Col>
					<Col span={24}>
						<MonthsTable limit={12} show={"Interactions"} data={this.state.monthData} />
					</Col>
					<Col span={24}>
						<MonthsTable limit={12} show={"Leads"} data={this.state.monthData} />
					</Col>
					<Col span={24}>
						<MonthsTable limit={12} show={"Loans"} data={this.state.monthData} />
					</Col>
					<Col span={24}>
						<Visits payees={this.state.payeesDataFromApi} id={this.props.match.params.id} onChange={this.getPayeeData} />
					</Col>
				</Row>
				<Add
					store={this.props.match.params.id}
					visible={this.state.addPayee}
					ok={() => {
						this.setState({
							addPayee: false,
						});
						return this.load();
					}}
					close={() => {
						this.setState({
							addPayee: false,
						});
					}}
				/>
			</>
		);
	}
}

export default StorePage;
