import React, { PureComponent } from "react";
import { Row, Col, Modal, Select, Button, Divider } from "antd";
import { connect } from "react-redux";
import { firestore } from "../../firebase";
import type { AssignProps, AssignState } from "./types";
import async from "async";
import axios from "axios";

class Assign extends PureComponent<AssignProps, AssignState> {
	constructor(props: AssignProps) {
		super();
		this.state = {
			id: props.id,
			data: null,
			b2b: null,
			payee: null,
			payees: [],
		};
	}

	async componentDidMount(): any {
		const referral = await firestore
			.collection("users/" + this.props.user.uid + "/referrals/")
			.where("name", "==", this.state.id)
			.get();
		if (referral.docs.length) {
			return this.setState(
				{
					data: {
						id: referral.docs[0].data().id,
						name: referral.docs[0].data().name,
						phone: referral.docs[0].data().phone,
						b2b: referral.docs[0].data().b2b,
						b2bName: referral.docs[0].data().b2bName,
						payee: referral.docs[0].data().payee,
						payeeName: referral.docs[0].data().payeeName,
					},
					b2b: referral.docs[0].data().b2b,
					payee: null,
				},
				() => {
					this.selectB2B(referral.docs[0].data().b2b);
				}
			);
		} else {
			return null;
		}
	}

	selectB2B = (id: null | string) => {
		if (id) {
			const b2bRef = firestore.doc("B2Bs/" + id.split("/")[0]);
			const payees = [];
			return firestore
				.collection("payees")
				.where("b2b", "==", b2bRef)
				.get()
				.then(payee => {
					return async.eachOf(
						payee.docs,
						(payee, key, callback) => {
							payees.push({
								id: payee.id,
								name: payee.data().name,
							});
							callback();
						},
						() => this.setState({ b2b: id, payee: null, payees: payees })
					);
				})
				.catch(e => {
					return e;
				});
		} else {
			return undefined;
		}
	};

	ok = async (e: any): any => {
		e.preventDefault();

		await axios({
			url: "https://b2b.discount-loans.com/assignReferral",
			params: {
				id: this.state.data ? this.state.data.id : 0,
				payee: this.state.payee,
			},
		});

		await firestore.doc("users/" + this.props.user.uid + "/referrals/" + this.state.id).delete();
		return this.cancel(true);
	};

	cancel = (ok?: boolean) => {
		return this.setState(
			{
				b2b: null,
				payee: null,
			},
			() => {
				if (ok) {
					return this.props.ok();
				} else {
					return this.props.close();
				}
			}
		);
	};

	render() {
		return (
			<Modal visible={this.props.visible} title={"Link to B2B Contact"} footer={null} onCancel={this.cancel}>
				{this.state.data ? (
					<>
						<b>Details</b>
						<Row gutter={[10, 10]}>
							<Col span={6}>
								<br />
								<i>Name:</i>
								<br />
								<i>Phone:</i>
								<br />
								<i>Store Front:</i>
								<br />
								<i>Temp Contact:</i>
							</Col>
							<Col span={18}>
								<br />
								{this.state.data.name}
								<br />
								{this.state.data.phone}
								<br />
								{this.state.data.b2bName}
								<br />
								{this.state.data.payeeName}
							</Col>
						</Row>
						<Divider />
					</>
				) : null}
				<Row gutter={[10, 10]}>
					<Col span={12}>
						Select B2B Store
						<Select style={{ width: "100%" }} value={this.state.b2b} onChange={this.selectB2B}>
							{this.props.b2bs.map(store => (
								<Select.Option key={store.id} value={store.id}>
									{store.company}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col span={12}>
						Select B2B Contact
						<Select style={{ width: "100%" }} value={this.state.payee} onChange={e => this.setState({ payee: e })}>
							{this.state.payees.map(payee => (
								<Select.Option key={payee.id} value={payee.id}>
									{payee.name}
								</Select.Option>
							))}
						</Select>
					</Col>
				</Row>

				<br />
				<br />

				<Row gutter={[10, 10]}>
					<Col span={24} style={{ textAlign: "right" }}>
						<Button onClick={() => this.cancel()} style={{ marginRight: 10 }}>
							Cancel
						</Button>
						<Button type={"primary"} onClick={this.ok} disabled={!this.state.b2b || !this.state.payee}>
							Assign
						</Button>
					</Col>
				</Row>
			</Modal>
		);
	}
}

const mapStateToProps = state => {
	return {
		b2bs: state.b2bs,
		user: state.user,
	};
};

//$FlowFixMe
export default connect(mapStateToProps)(Assign);
