import React from "react";
import { Input, Modal, Select } from "antd";

class InteractionsModal extends React.PureComponent<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			notes: props.notes ? props.notes : "",
		};
	}

	render() {
		const { visible, onOk, onCancel, data, handlePayeeChange, payeeSelect, interaction } = this.props;
		return (
			<Modal
				title={interaction ? "Update Visit" : "New Sales Visit"}
				visible={visible}
				onOk={() => onOk(this.state.notes)}
				onCancel={onCancel}
				okText={interaction ? "Update" : "Add"}
				cancelText={"Cancel"}>
				<Select
					style={{ width: "100%", marginBottom: 10 }}
					value={payeeSelect}
					mode="multiple"
					placeholder="Please Select Contact"
					onChange={handlePayeeChange}>
					{data.map((payee, index) => (
						<Select.Option key={index} value={payee.key}>
							{payee.payeeName}
						</Select.Option>
					))}
				</Select>
				<Input.TextArea
					rows={8}
					name="notes"
					value={this.state.notes}
					onChange={e => this.setState({ notes: e.currentTarget.value })}
					placeholder="Notes"
				/>
			</Modal>
		);
	}
}

export default InteractionsModal;
