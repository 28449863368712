import React, { useState } from "react";
import { Form, Button, Col, Input, InputNumber, Modal, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { firestore } from "../../firebase";
import axios from "axios";

export default function(props) {
	const [form] = Form.useForm();
	const b2bs = useSelector(state => state.b2bs);
	const user = useSelector(state => state.user);
	const territories = useSelector(state => state.territories);

	const [loading, setLoading] = useState(false);
	const [manual, setManual] = useState(false);
	const [payees, setPayees] = useState([]);

	const selectB2B = async (id: string) => {
		if (id) {
			const b2bRef = firestore.doc("B2Bs/" + id);
			const payees = await firestore
				.collection("payees")
				.where("b2b", "==", b2bRef)
				.get();
			return setPayees(
				payees.docs.map(x => ({
					id: x.id,
					name: x.data().name,
				}))
			);
		} else {
			return setPayees([]);
		}
	};

	const onFinish = async values => {
		setLoading(true);

		let payee = values.payee;
		if (manual && values.payeeName && values.payeePhone) {
			try {
				const newPayee = await axios({
					url: "https://b2b.discount-loans.com/payee",
					method: "POST",
					params: {
						b2b: values.store,
						newPayeeName: values.payeeName,
						newPayeePhone: values.payeePhone,
					},
				});
				payee = newPayee.data;
			} catch (err) {
				setLoading(false);
				return Modal.error({
					title: "Something went wrong",
					content: err.response.data.message,
				});
			}
		}

		await axios({
			url: "https://b2b.discount-loans.com/addReferral",
			method: "GET",
			params: {
				name: values.name,
				phone: values.phone,
				payee: payee ?? 0,
				branch: user.store ?? 117,
				territory: user.territory ?? values.territory ?? null,
			},
		});

		setLoading(false);
		return props.ok();
	};

	const cancel = () => {
		form.resetFields();
		props.close();
	};

	return (
		<Modal
			visible={props.visible}
			title={"Add Referral"}
			footer={[
				<Button onClick={cancel} style={{ marginRight: 10 }}>
					Cancel
				</Button>,
				<Button onClick={form.submit} loading={loading} type={"primary"}>
					Save
				</Button>,
			]}
			onCancel={cancel}>
			<Form onFinish={onFinish} form={form} layout={"vertical"}>
				<Row gutter={[10, 0]}>
					<Col span={12}>
						<Form.Item label="Customer's Name" name="name" rules={[{ required: true, message: "Please input a name" }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Customer's Phone" name="phone" rules={[{ required: true, message: "Please input a valid phone" }]}>
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					{!user.territory ? (
						<Col span={24}>
							<Form.Item label="Territory" name={"territory"} rules={[{ required: true, message: "Please select a territory" }]}>
								<Select>
									{territories?.map(territory => (
										<Select.Option key={territory.id} value={territory.id}>
											{territory.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					) : null}
					<Col span={12}>
						<Form.Item label="B2B Store" name={"store"}>
							<Select
								onChange={selectB2B}
								showSearch
								filterOption={(input, option) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{b2bs.map(store => (
									<Select.Option key={store.id} value={store.id}>
										{store.company} <br />
										<span style={{ fontSize: 12, color: "#AAA" }}>{store.address}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="B2B Contact" name={"payee"}>
							<Select onChange={e => setManual(e === "null")}>
								<Select.Option value={"null"} style={{ color: "#0985E6" }}>
									Create New B2B Contact
								</Select.Option>
								{payees.map(payee => (
									<Select.Option key={payee.id} value={payee.id}>
										{payee.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					{manual ? (
						<>
							<Col span={12}>
								<Form.Item label="B2B Contact's Name" name="payeeName">
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Contact's Phone" name="payeePhone">
									<InputNumber style={{ width: "100%" }} />
								</Form.Item>
							</Col>
						</>
					) : null}
				</Row>
			</Form>
		</Modal>
	);
}
