import React from "react";

// $FlowFixMe
const EditableRow = ({ form, index, ...props }) => (
	<EditableContext.Provider value={form}>
		<tr key={index} {...props} />
	</EditableContext.Provider>
);

export default EditableRow;
export const EditableContext: any = React.createContext();
