import axios from "axios";
import React, { useState } from "react";
import { Modal, Form, Row, Col, Select, Button, Input, InputNumber } from "antd";
import { useSelector } from "react-redux";
import { firestore } from "../../firebase";

export default function(props) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const stores = useSelector(state => state.b2bs);
	const [payees, setPayees] = useState([]);
	const [manual, setManual] = useState(false);

	const selectB2B = async (id: string) => {
		if (id) {
			const b2bRef = firestore.doc("B2Bs/" + id);
			const payees = await firestore
				.collection("payees")
				.where("b2b", "==", b2bRef)
				.get();
			return setPayees(
				payees.docs.map(x => ({
					id: x.id,
					name: x.data().name,
				}))
			);
		} else {
			return setPayees([]);
		}
	};

	const onFinish = async values => {
		setLoading(true);

		let payee = values.payee;
		if (manual) {
			try {
				const newPayee = await axios({
					url: "https://b2b.discount-loans.com/payee",
					method: "POST",
					data: {
						store: values.store,
						name: values.payeeName,
						phone: values.payeePhone,
					},
				});
				payee = newPayee.data;
			} catch (err) {
				setLoading(false);
				return Modal.error({
					title: "Something went wrong",
					content: err.response.data.message,
				});
			}
		}

		await axios({
			method: "GET",
			url: "https://b2b.discount-loans.com/updateReferral",
			params: {
				id: props.referral,
				payee_id: payee,
			},
		});

		form.resetFields();
		props.ok();
		props.close();
		setLoading(false);
	};

	const cancel = () => {
		form.resetFields();
		props.close();
	};

	return (
		<Modal
			title="Link Referral to B2B Contact and to B2B Store"
			visible={props.visible}
			onCancel={cancel}
			footer={[
				<Button onClick={cancel} style={{ marginRight: 10 }}>
					Cancel
				</Button>,
				<Button onClick={form.submit} loading={loading} type={"primary"}>
					Save
				</Button>,
			]}>
			<Form form={form} onFinish={onFinish} layout={"vertical"}>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item label="B2B Store" name={"store"} rules={[{ required: true, message: "Please select a B2B Store" }]}>
							<Select
								onChange={selectB2B}
								showSearch
								filterOption={(input, option) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{stores.map(store => (
									<Select.Option key={store.id} value={store.id}>
										{store.company} <br />
										<span style={{ fontSize: 12, color: "#AAA" }}>{store.address}</span>
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="B2B Contact" name={"payee"} rules={[{ required: true, message: "Please select a B2B Contact" }]}>
							<Select onChange={e => setManual(e === "null")}>
								<Select.Option value={"null"} style={{ color: "#0985E6" }}>
									Create New B2B Contact
								</Select.Option>
								{payees.map(payee => (
									<Select.Option key={payee.id} value={payee.id}>
										{payee.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					{manual ? (
						<>
							<Col span={12}>
								<Form.Item
									label="B2B Contact's Name"
									name="payeeName"
									rules={[{ required: true, message: "Please write the B2B Contact's name" }]}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label="B2B Contact's Phone"
									name="payeePhone"
									rules={[{ required: true, message: "Please write the B2B Contact's phone" }]}>
									<InputNumber style={{ width: "100%" }} />
								</Form.Item>
							</Col>
						</>
					) : null}
				</Row>
			</Form>
		</Modal>
	);
}
