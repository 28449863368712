import React from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

import { Button, DatePicker, Row, Col, Select } from "antd";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import Table from "../../components/smartTable";
import type { Props, State } from "./types";

const { RangePicker } = DatePicker;

class DashboardPage extends React.Component<Props, State> {
	state = {
		data: [],
		currentData: [],
		dataFilter: "",
		loading: true,
		storesNumber: 0,
		searchedColumn: "",
		searchText: "",
		territories: null,
		fromDate: moment().startOf("week"),
		toDate: moment(),
	};

	componentDidMount(): void {
		this.getAllData();
	}

	componentWillUnmount(): void {
		this.getAllData();
	}

	getAllData = () => {
		this.setState({ loading: true });
		const fromDate = this.state.fromDate.toDate();
		const toDate = this.state.toDate.toDate();
		return axios({
			url: "https://b2b.discount-loans.com/dashboard",
			params: {
				key: "CAEF9465AF4F4118A45DACC15EC92",
				territories: this.state.territories,
				uid: this.props.uid,
				fromDate,
				toDate,
			},
		}).then(res => {
			return this.setState({
				data: res.data.data,
				currentData: res.data.data,
				dataFilter: res.data.filter,
				storesNumber: res.data.data.length,
				loading: false,
			});
		});
	};

	searchDates = () => {
		this.setState({ loading: true });
		return this.getAllData();
	};

	onRangePickerChange = (dates: any) => {
		this.setState({
			fromDate: dates[0] ? dates[0] : moment().startOf("month"),
			toDate: dates[1] ? dates[1] : moment(),
		});
	};

	render() {
		// let payees = 0;
		let visits = 0;
		// let interactions = 0;
		// let frequency = 0;
		// let daysLastSeen = 0;
		let leads = 0;
		let loans = 0;
		// let conversion = 0;
		// let bonuses = 0;
		if (this.state.currentData.length) {
			this.state.currentData.forEach(item => {
				// payees += item.payees;
				visits += item.visits;
				// interactions += item.interactions;
				// daysLastSeen += item.daysLastSeen ? item.daysLastSeen : 0;
				// frequency += item.frequency;
				leads += item.leads;
				loans += item.loans;
				// conversion += item.conversion;
				// bonuses += item.commission;
			});
			// frequency = frequency / this.state.storesNumber;
			// daysLastSeen = daysLastSeen / this.state.storesNumber;
			// conversion = conversion / this.state.storesNumber;
		}
		const columns = [
			{
				title: `${this.state.storesNumber} B2B Stores`,
				sorter: (a, b) => a.name.localeCompare(b.name),
				search: "name",
				render: item => <Link to={"/store/" + item.id}>{item.name}</Link>,
			},
			{
				title: "Type",
				dataIndex: "type",
				filters: [
					{
						text: "Finance",
						value: "Finance",
					},
					{
						text: "Non Finance",
						value: "Non Finance",
					},
				],
				onFilter: (value, record) => record.type === value,
			},
			{
				title: `${visits} Sales Visits`,
				dataIndex: "visits",
				sorter: (a, b) => a.visits - b.visits,
				width: 200,
			},
			// {
			// 	title: `${frequency.toFixed(0)}% Frequency`,
			// 	dataIndex: "frequency",
			// 	sorter: (a, b) => a.frequency - b.frequency,
			// 	render: (index, item) => <p>{item.frequency === null ? 0 : item.frequency}%</p>,
			// },
			{
				title: `${leads} Referral Leads`,
				dataIndex: "leads",
				sorter: (a, b) => a.leads - b.leads,
				width: 200,
			},
			{
				title: `${loans} Loans`,
				dataIndex: "loans",
				sorter: (a, b) => a.loans - b.loans,
				width: 200,
			},
			// {
			// 	title: `${conversion.toFixed(0)}% Conversion`,
			// 	dataIndex: "conversion",
			// 	sorter: (a, b) => a.conversion - b.conversion,
			// 	render: (index, item) => <p>{item.conversion}%</p>,
			// },
			// {
			// 	title: `$${bonuses} Earned Bonuses`,
			// 	dataIndex: "commission",
			// 	sorter: (a, b) => a.commission - b.commission,
			// 	render: (index, item) => <p>${item.commission === null ? 0 : item.commission}</p>,
			// },
		];
		return (
			<div>
				<h1>Dashboard Overview {this.state.dataFilter ? `(${this.state.dataFilter})` : null}</h1>
				<Row style={{ paddingBottom: 10 }} gutter={5}>
					<Col span={24}>
						{this.props.user.role === "Admin" || this.props.user.role === "Manager" ? (
							<Select
								placeholder={"Select Territory"}
								mode="multiple"
								style={{ width: "46%", marginRight: 5 }}
								onChange={e =>
									this.setState({
										territories: e,
									})
								}>
								{this.props.territories.map(territory => (
									<Select.Option key={territory.id} value={territory.id} className="year-item">
										{territory.name}
									</Select.Option>
								))}
							</Select>
						) : null}
						<RangePicker
							defaultValue={[this.state.fromDate, this.state.toDate]}
							style={{ width: "30%" }}
							format="ll"
							onChange={this.onRangePickerChange}
						/>
						<Button
							style={{ marginLeft: 5 }}
							onClick={() => {
								this.searchDates();
								this.getAllData();
							}}
							type="primary">
							Search
						</Button>
						<CSVLink
							style={{ marginLeft: 5 }}
							data={this.state.data.map(x => ({
								name: x.name,
								address: x.address,
								type: x.type,
								territory: x.territoryName,
								payees: x.payees,
								visits: x.visits,
								interactions: x.interactions,
								lastSeen: x.lastSeen ? moment(x.lastSeen).format("MM/DD/YY") : "-",
								referralLeads: x.leads,
								loans: x.loans,
								conversion: x.conversion,
								commission: x.commission,
							}))}
							filename={"dashboard.csv"}>
							<Button type="primary">Download</Button>
						</CSVLink>
					</Col>
				</Row>
				<Table
					onChange={(pagination: number, filters: string, sorter: any, extra: Object) => {
						this.setState({
							currentData: extra.currentDataSource,
							storesNumber: extra.currentDataSource.length,
						});
					}}
					dataSource={this.state.data}
					loading={this.state.loading}
					columns={columns}
					rowKey={"id"}
					bordered
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	b2bs: state.b2bs,
	user: state.user,
	territories: state.territories,
	uid: state.user.uid,
});

//$FlowFixMe
export default connect(mapStateToProps)(DashboardPage);
