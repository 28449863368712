import React from "react";
import "../app/App.css";

type Props = {
	children: any,
};

const Cardbox = (props: Props) => {
	return (
		<div
			className="remove-print-margin"
			style={{
				margin: 20,
			}}>
			{props.children}
		</div>
	);
};

export default Cardbox;
