import { Table, Typography, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { State } from "./types";
import moment from "moment";
import React from "react";

function Payees(props: { state: State }) {
	let interactionsTotal = 0;
	let leadsTotal = 0;
	let loansTotal = 0;
	props.state.payeesDataFromApi.forEach(payee => {
		interactionsTotal += payee.userTotalInteractions;
		leadsTotal += payee.userTotalLeads;
		loansTotal += payee.userTotalLoans;
	});
	const columns = [
		{
			title: "Contact",
			dataIndex: "payeeName",
		},
		// {
		// 	title: "Last Login",
		// 	dataIndex: "metadata.lastSignInTime",
		// 	render: date => <span>{date ? moment(date).fromNow() : "N/A"}</span>,
		// },
		{
			title: "Last Login",
			dataIndex: "lastActive",
			render: date => (date ? moment(date.toDate()).fromNow() : "N/A"),
		},
		{
			title: `(${interactionsTotal}) Interactions`,
			dataIndex: "userTotalInteractions",
		},
		{
			title: `(${leadsTotal}) Leads`,
			dataIndex: "userTotalLeads",
		},
		{
			title: `(${loansTotal}) Loans`,
			dataIndex: "userTotalLoans",
		},
	];
	return (
		<>
			<Typography.Title level={3}>
				Contacts{" "}
				<Button
					type={"primary"}
					shape={"circle"}
					size={"small"}
					onClick={props.onAdd}
					icon={<PlusOutlined />}
					style={{ position: "relative", bottom: 5 }}
				/>
			</Typography.Title>
			<Table bordered loading={props.state.loading} columns={columns} pagination={false} scroll={{ y: 340 }} dataSource={props.state.payeesDataFromApi} />
		</>
	);
}

export default Payees;
