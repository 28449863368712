const data = {
	defaultWeek: [
		{ value: null, text: "Unassign" },
		{ value: 1, text: "Week 1" },
		{ value: 2, text: "Week 2" },
		{ value: 3, text: "Week 3" },
		{ value: 4, text: "Week 4" },
	],
	defaultDay: [
		{ value: null, text: "Unassign" },
		{ value: 1, text: "Monday" },
		{ value: 2, text: "Tuesday" },
		{ value: 3, text: "Wednesday" },
		{ value: 4, text: "Thursday" },
		{ value: 5, text: "Friday" },
		{ value: 6, text: "Saturday" },
	],
	defaultStop: [
		{ value: null, text: "Unassign" },
		{ value: 1, text: "Stop #1" },
		{ value: 2, text: "Stop #2" },
		{ value: 3, text: "Stop #3" },
		{ value: 4, text: "Stop #4" },
		{ value: 5, text: "Stop #5" },
		{ value: 6, text: "Stop #6" },
		{ value: 7, text: "Stop #7" },
		{ value: 8, text: "Stop #8" },
		{ value: 9, text: "Stop #9" },
		{ value: 10, text: "Stop #10" },
		{ value: 11, text: "Stop #11" },
		{ value: 12, text: "Stop #12" },
		{ value: 13, text: "Stop #13" },
		{ value: 14, text: "Stop #14" },
		{ value: 15, text: "Stop #15" },
		{ value: 16, text: "Stop #16" },
		{ value: 17, text: "Stop #17" },
		{ value: 18, text: "Stop #18" },
		{ value: 19, text: "Stop #19" },
		{ value: 20, text: "Stop #20" },
	],
};

export default data;
