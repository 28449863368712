import { combineReducers } from "redux";
import user from "./user";
import b2bs from "./b2bs";
import territories from "./territories";
import regions from "./regions";

const reducers: any = combineReducers({
	user,
	b2bs,
	territories,
	regions,
});
export default reducers;
