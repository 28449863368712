import React from "react";
import { Select } from "antd";
import { firestore } from "../../firebase";
import { connect } from "react-redux";
import getStores from "../../redux/actions/getStores";

type Props = {
	store: {
		id: string,
		type: string,
	},
	getStores: Function,
};
type State = {
	type: string,
};

class Type extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			type: props.store.type || props.store.type === undefined,
		};
	}

	saveType = async () => {
		await firestore.doc("B2Bs/" + this.props.store.id).update({ type: this.state.type });
		this.props.getStores();
	};

	render() {
		return (
			<Select style={{ width: 100 }} defaultValue={this.state.type} onChange={e => this.setState({ type: e }, this.saveType)}>
				<Select.Option key={0} value={"Finance"}>
					Finance
				</Select.Option>
				<Select.Option key={1} value={"Non Finance"}>
					Non Finance
				</Select.Option>
			</Select>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getStores: () => dispatch(getStores()),
	};
};

// $FlowFixMe
export default connect(null, mapDispatchToProps)(Type);
