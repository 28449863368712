import React from "react";
import { connect } from "react-redux";
import { Col, Form, Input, Modal, Row, Select } from "antd";

type Props = {
	visible: boolean,
	onOk: Function,
	onCancel: Function,
	edit: boolean,
	onChange: Function,
	title: string,
	state: {
		company: string,
		type: string,
		territory: string,
		address: string,
		city: string,
		zip: number,
		zips: Array<number>,
	},
	zips: Array<number>,
	territories: Array<Object>,
};

const Add = (props: Props) => {
	const { visible, onOk, onCancel, edit, title, onChange, state, zips, territories } = props;
	return (
		<Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel} onText={"Add"} cancelText={"Cancel"}>
			<Form layout="vertical">
				<Row type="flex" gutter={2}>
					<Row gutter={5}>
						<Col span={12}>
							<Form.Item label="Company">
								<Input required name="company" value={state.company} onChange={onChange} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Type">
								<Select required name="type" value={state.type} onChange={e => onChange({ target: { name: "type", value: e } })}>
									<Select.Option value={"Finance"}>Finance</Select.Option>
									<Select.Option value={"Non Finance"}>Non Finance</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Address">
								<Input required name="address" value={state.address} onChange={onChange} />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="City">
								<Input required name="city" value={state.city} onChange={onChange} />
							</Form.Item>
						</Col>
						{edit ? null : (
							<>
								<Col span={12}>
									<Form.Item label="Zip">
										{!zips ? (
											<Select
												mode="tags"
												defaultValue={state.zips}
												value={state.zips}
												onChange={e => onChange({ target: { name: "zip", value: e } })}
												dropdownStyle={{
													display: "none",
												}}
											/>
										) : (
											<Select value={state.zip} onChange={e => onChange({ target: { name: "zip", value: e } })}>
												{zips.map(zip => (
													<Select.Option key={state.zip} value={zip}>
														{zip}
													</Select.Option>
												))}
											</Select>
										)}
									</Form.Item>
								</Col>
							</>
						)}
						<Col span={12}>
							<Form.Item label="Territory">
								<Select
									required
									placeholder="Select a Territory"
									value={state.territory}
									onChange={e => onChange({ target: { name: "territory", value: e } })}>
									{territories.map(x => (
										<Select.Option key={x.id} value={x.id}>
											{x.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</Row>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state: Object) => ({
	zips: state.user.zips,
	user: state.user.role,
});

//$FlowFixMe
export default connect(mapStateToProps)(Add);
