import { firestore } from "../../firebase";

export default () => {
	return async (dispatch: Function, getState: Function) => {
		const { user, territories } = getState();
		let remoteStores;

		if (user.role === "Admin") {
			remoteStores = await firestore
				.collection("B2Bs")
				.orderBy("company", "asc")
				.get();
		} else if (user.role === "Manager") {
			const managerTerritories = user.regionTerritories.map(x => firestore.doc("territories/" + x.id));
			remoteStores = await firestore
				.collection("B2Bs")
				.orderBy("company", "asc")
				.where("territory", "in", managerTerritories)
				.get();
		} else {
			const zips = user.zips;
			const allStores = await firestore
				.collection("B2Bs")
				.orderBy("company", "asc")
				.get();

			remoteStores = {
				docs: allStores.docs.filter(x => zips.includes(x.data().zip)),
			};
		}

		dispatch({
			type: "ADD_B2BS",
			payload: remoteStores.docs.map(x => {
				// Let's assign multiple territories to same B2B
				const storeTerritories = territories.filter(y => y.zips.includes(x.data().zip));
				return {
					...x.data(),
					id: x.id,
					territory: x.data()?.territory?.id,
					territories: storeTerritories.map(x => x.name),
				};
			}),
		});
	};
};
