type ADD_B2BS = {
	type: "ADD_B2BS",
	payload: Array<{
		id: string,
		city: string,
		company: string,
		territory: string,
		zip: string,

		defaultDay?: number,
		defaultWeek?: number,
		defaultStop?: number,
		day?: number,
		week?: number,
		stop?: number,
	}>,
};

type REMOVE_B2BS = {
	type: "REMOVE_B2BS",
};

const b2bReducer = (state: Array<Object> = [], action: ADD_B2BS | REMOVE_B2BS): Array<Object> => {
	switch (action.type) {
		case "ADD_B2BS":
			return action.payload;
		case "REMOVE_B2BS":
			return [];
		default:
			return state;
	}
};

export default b2bReducer;
