import React from "react";
import Cardbox from "../../components/cardbox";
import { connect } from "react-redux";
import { Form, Input, Button, notification } from "antd";
import { auth, EmailAuthProvider } from "../../firebase";
import type { Props, State } from "./types";

class ProfilePage extends React.Component<Props, State> {
	state = {
		currentPassword: "",
		newPassword: "",
		comparePassword: "",
	};

	updateUserProfile = () => {
		const { newPassword, comparePassword, currentPassword } = this.state;
		const user = auth.currentUser;
		if (!currentPassword) {
			return notification.success({ message: "Ooops!", description: "Please provide your current password." });
		}
		let credential = EmailAuthProvider.credential(this.props.email, currentPassword);

		if (!newPassword || !comparePassword) {
			return notification.error({
				message: "Ooops! Something went wrong.",
				description: "If you wish to change your password it cannot be blank.",
			});
		} else if (user) {
			return user
				.reauthenticateWithCredential(credential)
				.then(() => {
					return user.updatePassword(newPassword);
				})
				.then(() => {
					this.setState({ currentPassword: "", newPassword: "", comparePassword: "" });
					return notification.success({
						message: "Success!",
						description: "Your password has been updated successfully!",
					});
				})
				.catch(err => notification.error({ message: "Ooops! Something went wrong", description: err.message }));
		} else {
			return false;
		}
	};

	render() {
		return (
			<Cardbox>
				<h1>Profile</h1>
				<Form style={{ width: "30%" }}>
					<Form.Item>
						<Input
							value={this.state.currentPassword}
							onChange={e => this.setState({ currentPassword: e.currentTarget.value })}
							style={styles.input}
							placeholder={"Current Password"}
						/>
					</Form.Item>
					<Form.Item>
						<Input
							value={this.state.newPassword}
							onChange={e => this.setState({ newPassword: e.currentTarget.value })}
							style={styles.input}
							placeholder={"New Password"}
						/>
					</Form.Item>
					<Form.Item>
						<Input
							value={this.state.comparePassword}
							onChange={e => this.setState({ comparePassword: e.currentTarget.value })}
							style={styles.input}
							placeholder={"Confirm Password"}
						/>
					</Form.Item>
					<Form.Item>
						<Button type={"primary"} onClick={() => this.updateUserProfile()}>
							Save
						</Button>
					</Form.Item>
				</Form>
			</Cardbox>
		);
	}
}

const mapStateToProps = state => ({
	displayName: state.user.name,
	email: state.user.email,
});

const styles = {
	input: {
		height: 40,
	},
};

//$FlowFixMe
export default connect(mapStateToProps)(ProfilePage);
