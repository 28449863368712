import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined, BarChartOutlined } from "@ant-design/icons";

type Props = {
	role: string,
};

const MenuBar = (props: Props) => {
	const { role } = props;
	const location = useLocation();
	const path = location.pathname;
	return (
		<Menu
			className="remove-print"
			selectedKeys={path}
			mode="horizontal"
			overflowedIndicator={<MenuOutlined />}
			style={{
				width: "100%",
				position: "fixed",
				background: "red",
				zIndex: 5,
				padding: 10,
				paddingLeft: 40,
				marginTop: 100,
			}}>
			<Menu.Item key="/">
				<Link style={style.a} to={"/"}>
					<i className={(path === "/" ? "fas" : "fal") + " fa-tachometer-alt-fast"} style={{ paddingRight: 10, fontSize: 18 }} />
					DASHBOARD
				</Link>
			</Menu.Item>
			{role === "Admin" || role === "Manager" ? (
				<Menu.SubMenu icon={<BarChartOutlined style={style.icon} />} title="REPORTS" style={style.a}>
					<Menu.Item key="/reports/leads">
						<Link style={style.a} to={"/reports/leads"}>
							LEADS
						</Link>
					</Menu.Item>
					<Menu.Item key="/reports/loans">
						<Link style={style.a} to={"/reports/loans"}>
							LOANS
						</Link>
					</Menu.Item>
					<Menu.Item key="/reports/visits">
						<Link style={style.a} to={"/reports/visits"}>
							VISITS
						</Link>
					</Menu.Item>
					<Menu.Item key="/reports/downloads">
						<Link style={style.a} to={"/reports/downloads"}>
							DOWNLOADS
						</Link>
					</Menu.Item>
				</Menu.SubMenu>
			) : null}
			<Menu.Item key="/referrals">
				<Link style={style.a} to={"/referrals"}>
					<i className={(path === "/referrals" ? "fas" : "fal") + " fa-users"} style={{ padding: 10, fontSize: 18 }} />
					REFERRALS
				</Link>
			</Menu.Item>
			<Menu.Item key="/payees">
				<Link style={style.a} to={"/payees"}>
					<i className={(path === "/payees" ? "fas" : "fal") + " fa-user-tie"} style={{ padding: 10, fontSize: 18 }} />
					B2B CONTACTS
				</Link>
			</Menu.Item>
			<Menu.Item key="/b2bs">
				<Link style={style.a} to={"/b2bs"}>
					<i className={(path === "/b2bs" || path.startsWith("/store/") ? "fas" : "fal") + " fa-store"} style={{ padding: 10, fontSize: 18 }} />
					B2B STORES
				</Link>
			</Menu.Item>
			<Menu.Item key="/order">
				<Link style={style.a} to={"/order"}>
					<i className={(path === "/order" ? "fas" : "fal") + " fa-table"} style={{ padding: 10, fontSize: 18 }} />
					ROUTING SCHEDULE
				</Link>
			</Menu.Item>
			<Menu.Item key="/weekly">
				<Link style={style.a} to={"/weekly"}>
					<i className={(path === "/weekly" ? "fas" : "fal") + " fa-calendar-week"} style={{ padding: 10, fontSize: 18 }} />
					WEEKLY
				</Link>
			</Menu.Item>
			<Menu.Item key="/calendar">
				<Link style={style.a} to={"/calendar"}>
					<i className={(path === "/calendar" ? "fas" : "fal") + " fa-calendar-alt"} style={{ padding: 10, fontSize: 18 }} />
					CALENDAR
				</Link>
			</Menu.Item>
			{role === "Admin" || role === "Manager" ? (
				<Menu.Item key="/check-logs">
					<Link style={style.a} to={"/check-logs"}>
						<i className={(path === "/check-logs" ? "fas" : "fal") + " fa-money-check"} style={{ padding: 10, fontSize: 18 }} />
						CHECK LOGS
					</Link>
				</Menu.Item>
			) : null}
			{role === "Admin" || role === "Manager" ? (
				<Menu.Item key="/notifications">
					<Link style={style.a} to={"/notifications"}>
						<i className={(path === "/notifications" ? "fas" : "fal") + " fa-bell"} style={{ padding: 10, fontSize: 18 }} />
						NOTIFICATIONS
					</Link>
				</Menu.Item>
			) : null}
			{role === "Admin" || role === "Manager" ? (
				<Menu.Item key="/users">
					<Link style={style.a} to={"/users"}>
						<i className={(path === "/users" ? "fas" : "fal") + " fa-users-cog"} style={{ padding: 10, fontSize: 18 }} />
						USERS
					</Link>
				</Menu.Item>
			) : null}
			{role === "Admin" || role === "Manager" ? (
				<Menu.Item key="/regions">
					<Link style={style.a} to={"/regions"}>
						<i className={(path === "/regions" ? "fas" : "fal") + " fa-map-marked-alt"} style={{ padding: 10, fontSize: 18 }} />
						REGIONS
					</Link>
				</Menu.Item>
			) : null}
			{role === "Admin" || role === "Manager" ? (
				<Menu.Item key="/territories">
					<Link style={style.a} to={"/territories"}>
						<i className={(path === "/territories" ? "fas" : "fal") + " fa-map-marked-alt"} style={{ padding: 10, fontSize: 18 }} />
						TERRITORIES
					</Link>
				</Menu.Item>
			) : null}
		</Menu>
	);
};

const style = {
	a: {
		fontSize: 14,
		letterSpacing: 1.5,
		color: "#fff",
	},
	icon: {
		fontSize: 20,
		letterSpacing: 1.5,
	},
};

export default MenuBar;
