import React, { useState } from "react";
import { Button, Input, InputNumber, Modal, Form } from "antd";
import axios from "axios";

export default function Add(props) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const onFinish = values => {
		setLoading(true);
		axios({
			url: "https://b2b.discount-loans.com/payee",
			method: "POST",
			params: {
				b2b: props.store,
				newPayeeName: values.name,
				newPayeePhone: values.phone,
			},
		})
			.then(() => {
				props.ok();
				return setLoading(false);
			})
			.catch(e => {
				Modal.error({
					title: "Something went wrong",
					content: e.response.data?.message ?? e.response.data?.["moreInfo"],
				});
				setLoading(false);
			});
	};

	const cancel = () => {
		form.resetFields();
		props.close();
	};

	return (
		<Modal
			visible={props.visible}
			title={"Add B2B Contact"}
			footer={[
				<Button onClick={cancel} style={{ marginRight: 10 }}>
					Cancel
				</Button>,
				<Button loading={loading} type={"primary"} onClick={form.submit}>
					Save
				</Button>,
			]}
			onCancel={cancel}>
			<Form form={form} onFinish={onFinish} layout={"vertical"}>
				<Form.Item name="name" label="B2B Contact Name" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item name="phone" label="B2B Contact Phone" rules={[{ required: true }]}>
					<InputNumber style={{ width: "100%" }} />
				</Form.Item>
			</Form>
		</Modal>
	);
}
