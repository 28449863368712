import React from "react";
import { Tag } from "antd";
import moment from "moment";

export const columns = (manipulateData: Function, getColumnSearchProps: Function, territories: Array) => [
	{
		title: "Date",
		dataIndex: "timestamp",
		render: (record: string) => <p>{moment(record.toDate()).format("lll")}</p>,
		sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
	},
	{
		title: "User",
		dataIndex: "user",
		...getColumnSearchProps("user"),
	},
	{
		title: "Title",
		dataIndex: "title",
		...getColumnSearchProps("title"),
	},
	{
		title: "Body",
		dataIndex: "body",
		...getColumnSearchProps("body"),
	},
	{
		title: "Territories",
		dataIndex: "territories",
		render: (record: any) => {
			return record.map(x => (
				<Tag key={x} color={"green"}>
					{territories.find(y => y.id === x)?.name ?? x}
				</Tag>
			));
		},
	},
];
