import axios from "axios";
import Redux from "../../redux";

export default (territories): Promise<Object> => {
	return new Promise(async resolve => {
		const state = Redux().store.getState();

		const leads: {
			data: Array<{
				created: Date,
				payeeId: number,
				storeId: number,
				application: string,
			}>,
		} = await axios({
			url: "https://b2b.discount-loans.com/referrals",
			params: {
				uid: state.user.uid,
				territories: territories,
				key: "CAEF9465AF4F4118A45DACC15EC92",
			},
		});

		return resolve({
			referrals: leads.data["data"],
			filter: leads.data.filter,
		});
	});
};
