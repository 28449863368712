import React from "react";
import { Row, Col, Typography, Input, Layout, Button, notification, Carousel } from "antd";
import { connect } from "react-redux";

import Logo from "../../assets/logoTitle@2x.png";
import { auth, firestore } from "../../firebase";
import { withRouter } from "react-router-dom";
import type { Props, State } from "./types";
import "./style.css";
import getStores from "../../redux/actions/getStores";
import getRegions from "../../redux/actions/getRegions";

class Auth extends React.Component<Props, State> {
	state = {
		email: "",
		password: "",
	};

	enterPressed = (evt): void => {
		if (evt.key === "Enter") {
			evt.preventDefault();
			evt.stopPropagation();
			this.loginUser().catch();
		}
	};

	loginUser = () => {
		return auth
			.signInWithEmailAndPassword(this.state.email, this.state.password)
			.then(async () => {
				notification.success({ message: "Login Succeeded!" });
				const user = auth.currentUser;
				if (!user) {
					return null;
				}
				const userData = await firestore.doc("users/" + user.uid).get();

				const territoriesCollection = await firestore
					.collection("territories")
					.orderBy("name", "asc")
					.get();

				const territories = territoriesCollection.docs.map(x => {
					return {
						...x.data(),
						id: x.id,
					};
				});
				this.props.getTerritories(territories);

				//$FlowFixMe
				let territory = null;
				let zips = [];
				if (userData.data().territory) {
					territory = await userData.data().territory.get();
					zips = territory?.data().zips;
				}

				//$FlowFixMe
				let region = null;
				let regionTerritories = [];
				if (userData.data().region) {
					region = await userData.data().region.get();
					const regionTerritoriesIDs = region?.data().territories.map(x => x.id);
					regionTerritories = regionTerritoriesIDs.map(x => {
						return {
							id: x,
							name: territories.find(territory => territory.id === x).name,
						};
					});
					zips = territories
						.filter(x => regionTerritoriesIDs.includes(x.id))
						.map(x => x?.zips)
						.reduce((a, b) => a.concat(b));
					this.props.getTerritories(territories.filter(x => regionTerritoriesIDs.includes(x.id)));
				}

				if (userData.data().role === "Admin") {
					zips = territories.map(x => x?.zips).reduce((a, b) => a.concat(b));
				}

				this.props.logIn({
					uid: user.uid,
					prismID: userData.data().prismID,
					name: userData.data().name,
					email: user.email,
					photoURL: user.photoURL,
					role: userData.data().role,
					store: territory ? territory?.data()["prismStore"] : region ? region?.data()["prismStore"] : null,
					territory: territory ? territory?.id : null,
					territoryName: territory ? territory?.data().name : null,
					region: region ? region?.data().name : null,
					regionTerritories: regionTerritories ?? [],
					zips: zips,
				});
				this.props.getB2Bs();
				this.props.getRegions();
				this.props.history.push("/");
				return null;
			})
			.catch(err => notification.error({ message: "Error", description: err.message }));
	};

	render() {
		const { email, password } = this.state;
		return (
			<Layout.Content>
				<Row>
					<Col md={12} xs={24} style={{ padding: 20, display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
						<img src={Logo} style={{ height: 88, position: "absolute", left: 10, top: 20 }} alt={"Discount Title Loans"} />
						<div style={{ textAlign: "center" }}>
							<Typography.Title level={2}>Good Day! Welcome Back</Typography.Title>
							<Typography.Title level={4} style={{ color: "#888", marginTop: -10, marginBottom: 20 }}>
								Enter your details here
							</Typography.Title>
							<div style={{ maxWidth: 320, margin: "auto", padding: 0 }}>
								<Input
									style={{ marginTop: 5, marginBottom: 5, height: 38 }}
									prefix={<i className="fal fa-user" />}
									placeholder="Email"
									onKeyDown={this.enterPressed}
									value={email}
									onChange={e => this.setState({ email: e.currentTarget.value })}
								/>
								<Input
									style={{ marginTop: 5, marginBottom: 5, height: 38 }}
									prefix={<i className="fal fa-lock" />}
									placeholder="Password"
									type={"password"}
									onKeyDown={this.enterPressed}
									value={password}
									onChange={e => this.setState({ password: e.currentTarget.value })}
								/>
								<br />
								<br />
								<Button htmlType="submit" onClick={() => this.loginUser()} type={"primary"} block>
									Login
								</Button>
							</div>
						</div>
						<span style={{ position: "absolute", bottom: 20 }}>
							Developed by{" "}
							<a href={"https://studiomediaagency.com"} target={"_blank"} rel="noopener noreferrer">
								Studio Media
							</a>
						</span>
					</Col>
					<Col md={12} xs={24} style={{ textAlign: "center", height: "100vh" }}>
						<Carousel autoplay={true} effect={"fade"}>
							<div className={"slide1"}>
								<div className={"content"}>
									<img src={require("../../assets/1.png")} alt={"Onboarding"} />
									<h1>Dashboard</h1>
									<p>Access an overview of your data in seconds</p>
								</div>
							</div>
							<div className={"slide2"}>
								<div className={"content"}>
									<img src={require("../../assets/2.png")} alt={"Onboarding"} />
									<h1>Referrals</h1>
									<p>Auto Link referrals with currently opened applications</p>
								</div>
							</div>
							<div className={"slide3"}>
								<div className={"content"}>
									<img src={require("../../assets/3.png")} alt={"Onboarding"} />
									<h1>Route</h1>
									<p>Select a default routing schedule the best fits your needs</p>
								</div>
							</div>
							<div className={"slide4"}>
								<div className={"content"}>
									<img src={require("../../assets/4.png")} alt={"Onboarding"} />
									<h1>Calendar</h1>
									<p>Create appointments and never miss a beat</p>
								</div>
							</div>
						</Carousel>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		logIn: payload => dispatch({ type: "LOGIN", payload }),
		getB2Bs: () => dispatch(getStores()),
		getRegions: () => dispatch(getRegions()),
		getTerritories: payload => dispatch({ type: "GET_TERRITORIES", payload }),
	};
};

// $FlowFixMe
export default connect(null, mapDispatchToProps)(withRouter(Auth));
